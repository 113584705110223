let CLIENT_SECRET_KEY = '';

if (
  window.location.hostname === "10.21.87.30" ||
  window.location.hostname === "localhost"
) {
  CLIENT_SECRET_KEY = "3c1bcfd3-8207-4eff-9cb2-0f90e61d6290";
} else if (window.location.hostname === "10.167.242.56") {
  CLIENT_SECRET_KEY = "e4550188-23aa-4359-861d-93a04489576d";
}else if(window.location.hostname==="gcprep-som-api.services.ajio.jio.com" || window.location.hostname==="gcprep-som.services.ajio.jio.com"){
   CLIENT_SECRET_KEY  ="405b239d-3047-45e2-838e-5e9c1f24016e";
} 
else {
  CLIENT_SECRET_KEY = "9ad6e299-294c-4cd9-9cb2-e91860b67fcd";
}

const config = {
  REACT_APP_CLIENT_SECRET_KEY: CLIENT_SECRET_KEY,
  DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm'
};

export default config;