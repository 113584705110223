/* eslint-disable consistent-return */
import axios from "axios";
import { getStoreOrigin, logout } from "../helpers/Utility"
import { KEYCLOAK } from "../constants";
import logError from './logErrorWAF';

axios.defaults.headers.common["Content-Type"] = "application/json";

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

axios.interceptors.response.use(
  rsp => {
    if (
      !!rsp.data.error_message &&
      rsp.data.error_message.toLowerCase() === "-1:session expired. login again." &&
      rsp.data.status_code === 401
    ) {
      let userOrigin = getStoreOrigin();
      logout();
      return null;
    } else if (rsp.status === 200 && rsp.headers["content-type"].includes("text/html")) {
      // handle WAF issue
      logError(rsp.config.method.toUpperCase(), rsp.config.url, rsp.config.data, rsp.data.replace(/\D+/g, ""));
      throw new axios.Cancel({
        res: {
          message: `An error occurred while performing this action. You may raise a ticket against support ID: ${rsp.data.replace(
            /\D+/g,
            ""
          )}`
        }
      });
    } else {
      return rsp;
    }

  },
  error => {
    if (error.response.data.message === "401 null") {
      /*
      * Added condition to redirect user to login screen
      */
      let userOrigin = getStoreOrigin();
      logout();

      //throw new axios.Cancel("Unauthorized access"); //removed this 
    } else if (error.response.status === 400 && error.response.data instanceof ArrayBuffer) {
      let clonedError = { ...error };
      if (error.response.data instanceof ArrayBuffer) {
        const decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
        clonedError.error = JSON.parse(decodedString);
      }
      const newError = clonedError
      return Promise.reject(newError);
    } else if (error.response.status === 400 && (error.response.data.message === "Invalid OTP." || error.response.data.message === "OTP expired.")) {
      let obj = error.response.data
      let clonedError = { ...obj };
      const newError = clonedError
      return Promise.reject(newError);
    } else if (error.response.status === 401) {
      const originalRequest = error.config;

      if (!originalRequest._retry) {
        // if (!error.config.url.includes('/login') && !originalRequest._retry) {
        // update access token
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          }).then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          }).catch(err => {
            return err
          })
        }
        originalRequest._retry = true;
        isRefreshing = true;

        const headers = {
          'realm': KEYCLOAK.realm
        };
        const body = {
          "payload": {
            "refreshToken": localStorage.getItem('refresh_token'),
            "clientId": KEYCLOAK.clientID,
            "clientSecret": KEYCLOAK.clientSecret,
            "grantType": "refresh_token",
          }
        }
        return new Promise(function (resolve, reject) {
          axios.post(`/dashboard/v1/keyCloak_refresh`, body, { headers })
            .then(({ data }) => {
              localStorage.setItem('access_token', data.result.accessToken);
              localStorage.setItem('refresh_token', data.result.refreshToken);
              originalRequest.headers['Authorization'] = 'Bearer ' + data.result.accessToken;
              processQueue(null, data.result.accessToken);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
              logout();
            })
            .then(() => { isRefreshing = false })
        })
      }
      if (error.config.url.includes('/refresh')) {
        // listener added to clear the storage data and redirect to login page
        // PubSub.publish('TOPIC_UNAUTHORIZEDEVENT');
        logout();
        // window.location = '/login';
        // cancel the axios request
        throw new axios.Cancel('Unauthorized access');
      }

      // }
    } else if (error.response.status === 400 && error.response.data.errors[0]['errorDescription'] === 'Bad Request : Refresh token expired') {
      logout();
    }
    return Promise.reject(error);
  }
);

export const INTERNAL_SERVER_ERROR = {
  code: "INTERNAL_SERVER_ERROR",
  number: 500,
  message: "Internet Server Error"
};
export const INTERNAL_CONNECTIVITY_ERROR = {
  code: "INTERNAL_CONNECTIVITY_ERROR",
  number: 502,
  message: "Internet Connectivity Error"
};
export const BAD_GATEWAY = {
  code: "BAD_GATEWAY",
  number: 502,
  message: "Internet Connectivity Error"
};
export const SERVICE_UNAVAILABLE = {
  code: "SERVICE_UNAVAILABLE",
  number: 503,
  message: "Service Unavailable"
};
export const GATEWAY_TIMEOUT = {
  code: "GATEWAY_TIMEOUT",
  number: 504,
  message: "Gateway timed out"
};
export const BAD_REQUEST = {
  code: "BAD_REQUEST",
  number: 400,
  message: "Bad Request"
};

export const UNAUTHORIZED = {
  code: "UNAUTHORIZED",
  number: 401,
  message: "Unauthorized"
};

export default {
  get(url, options) {
    return axios.get(url, options);
  },
  post(url, data, options) {
    return axios.post(url, data, { ...options }).then(response => response);
  },
  put(url, data, options) {
    return axios.put(url, data, { ...options }).then(response => response);
  },
  delete(url, options) {
    return axios.delete(url, { ...options }).then(response => response);
  },
  ajax(options) {
    return axios(options);
  },
  getError(err) {
    let errTpl = {};
    if (err.response && err.response.status) {
      switch (Number(err.response.status)) {
        case 500:
          try {
            errTpl.data = JSON.parse(err.response.data);
          } catch (e) {
            if (err.response.data.indexOf("ECONNREFUSED") > -1) {
              errTpl = INTERNAL_CONNECTIVITY_ERROR;
            }
            errTpl = INTERNAL_SERVER_ERROR;
          }
          break;
        case 502:
          errTpl = BAD_GATEWAY;
          break;
        case 503:
          errTpl = SERVICE_UNAVAILABLE;
          break;
        case 504:
          errTpl = GATEWAY_TIMEOUT;
          break;
        case 400:
          errTpl = BAD_REQUEST;
          break;
        case 401:
          errTpl = UNAUTHORIZED;
          break;
        default:
          errTpl = INTERNAL_SERVER_ERROR;
      }
    } else {
      errTpl = INTERNAL_CONNECTIVITY_ERROR;
    }
    return errTpl;
  },
  error: {
    INTERNAL_SERVER_ERROR,
    INTERNAL_CONNECTIVITY_ERROR,
    BAD_GATEWAY,
    SERVICE_UNAVAILABLE,
    GATEWAY_TIMEOUT,
    BAD_REQUEST,
    UNAUTHORIZED
  }
};
