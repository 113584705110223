const PICKER_PACKER_MANIFEST_TRENDS = [
  {
    path: "/som/sfs",
    menuName: "Ship From Store",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "ShipfromStore",
    expand: true,
    subMenu: [
      {
        path: "/som/shipFromStore/page/searchOrder",
        menuName: "Search Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true,
        default: false,
      },
      {
        path: "/som/sfs/openOrder",
        menuName: "Open Order",
        importPath: "ShipFromStore/Orders/OpenOrders",
        component: "Orders",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/orderDetails/:shipment_id",
        menuName: "Order Details",
        importPath: "ShipFromStore/Orders/OrderDetails/OrderDetails",
        component: "OrderDetails",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/page/confirmResolution/:shipment_id",
        menuName: "Pending Resolution",
        importPath: "ShipFromStore/PendingResolution/ConfirmResolution",
        component: "ConfirmResolution",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/page/confirmOrders",
        menuName: "Confirm Orders",
        importPath: "ShipFromStore/AcceptedOrders/ConfirmOrders",
        component: "AcceptedOrders",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      // {
      //   path: "/som/sfs/page/manualPrint",
      //   menuName: "Manual Print",
      //   importPath: "ShipFromStore/ManualPrint/ManualPrint",
      //   component: "ManualPrint",
      //   showOnMenu: true,
      //   default: false,
      //   isKeyCloak: true,
      // },
      {
        path: "/som/readyToPack",
        menuName: "Ready To Pack",
        importPath: "ShipFromStore/Packing/ReadyToPack",
        component: "ReadyToPack",
        showOnMenu: true,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/packedOrderList",
        menuName: "Packed Orders",
        importPath: "ShipFromStore/Packed/PackedOrderList",
        component: "PackedOrders",
        showOnMenu: true,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/packConfirmation/:shipment_id",
        menuName: "Packing Confirmation",
        importPath: "ShipFromStore/Packing/PackConfirmation",
        component: "PackingConfirmation",
        showOnMenu: false,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/manifestListing",
        menuName: "Close Manifest",
        importPath: "ShipFromStore/Manifest/ManifestListing",
        component: "ManifestListing",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/manifestDetails",
        menuName: "Manifest Details",
        importPath: "ShipFromStore/Manifest/ManifestDetails",
        component: "ManifestDetails",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/incentives",
        menuName: "Fullfilment Incentive",
        importPath: "Incentives/Incentives",
        component: "Incentives",
        showOnMenu: true,
      }
    ],
  }
];
const PICKER_PACKER_MANIFEST_SUPERVISOR = [
  {
    path: "/som/sfs",
    menuName: "Ship From Store",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    expand: true,
    icon: "ShipfromStore",
    subMenu: [
      {
        path: "/som/shipFromStore/page/searchOrder",
        menuName: "Search Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true,
        default: false,
      },
      {
        path: "/som/sfs/openOrder",
        menuName: "Open Order",
        importPath: "ShipFromStore/Orders/OpenOrders",
        component: "Orders",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/orderDetails/:shipment_id",
        menuName: "Acknowledge Order",
        importPath: "ShipFromStore/Orders/OrderDetails/OrderDetails",
        component: "OrderDetails",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/picklistAssignment",
        menuName: "Picklist Assignment",
        importPath: "ShipFromStore/PickListAssignment/PickListAssignment",
        component: "PicklistAssignment",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/manualReprint",
        menuName: "Manual Reprint",
        importPath: "ShipFromStore/ManualPrint/ManualPrint",
        component: "ManualPrint",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/pendingResolution",
        menuName: "Pending Resolution",
        importPath: "ShipFromStore/PendingResolution/PendingResolution",
        component: "PendingResolution",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/confirmResolution/:shipment_id",
        menuName: "Pending Resolution",
        importPath: "ShipFromStore/PendingResolution/ConfirmResolution",
        component: "ConfirmResolution",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/readyToPack",
        menuName: "Ready To Pack",
        importPath: "ShipFromStore/Packing/ReadyToPack",
        component: "ReadyToPack",
        showOnMenu: true,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/packConfirmation/:shipment_id",
        menuName: "Packing Confirmation",
        importPath: "ShipFromStore/Packing/PackConfirmation",
        component: "PackingConfirmation",
        showOnMenu: false,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/packedOrderList",
        menuName: "Packed Orders",
        importPath: "ShipFromStore/Packed/PackedOrderList",
        component: "PackedOrders",
        showOnMenu: true,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/manifestListing",
        menuName: "Close Manifest",
        importPath: "ShipFromStore/Manifest/ManifestListing",
        component: "ManifestListing",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/manifestDetails",
        menuName: "Manifest Details",
        importPath: "ShipFromStore/Manifest/ManifestDetails",
        component: "ManifestDetails",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/incentives",
        menuName: "Fullfilment Incentive",
        importPath: "Incentives/Incentives",
        component: "Incentives",
        showOnMenu: true,
      },
    ],
  },
];
const PICKER_PACKER_MANIFEST_NONTRENDS = [
  {
    path: "/som/sfs",
    menuName: "Ship From Store",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    expand: true,
    icon: "ShipfromStore",
    subMenu: [
      {
        path: "/som/shipFromStore/page/searchOrder",
        menuName: "Search Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true,
        default: false,
      },
      {
        path: "/som/sfs/openOrder",
        menuName: "Open Order",
        importPath: "ShipFromStore/Orders/OpenOrders",
        component: "Orders",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/orderDetails/:shipment_id",
        menuName: "Order Details",
        importPath: "ShipFromStore/Orders/OrderDetails/OrderDetails",
        component: "OrderDetails",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/page/manualPrint",
        menuName: "Manual Print",
        importPath: "ShipFromStore/ManualPrint/ManualPrint",
        component: "ManualPrint",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/readyToPack",
        menuName: "Ready To Pack",
        importPath: "ShipFromStore/Packing/ReadyToPack",
        component: "ReadyToPack",
        showOnMenu: true,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/packConfirmation/:shipment_id",
        menuName: "Packing Confirmation",
        importPath: "ShipFromStore/Packing/PackConfirmation",
        component: "PackingConfirmation",
        showOnMenu: false,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/packedOrderList",
        menuName: "Packed Orders",
        importPath: "ShipFromStore/Packed/PackedOrderList",
        component: "PackedOrders",
        showOnMenu: true,
        icon: "",
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/manifestListing",
        menuName: "Close Manifest",
        importPath: "ShipFromStore/Manifest/ManifestListing",
        component: "ManifestListing",
        showOnMenu: true,
        default: false,
        isKeyCloak: true,
      },
      {
        path: "/som/sfs/manifestDetails",
        menuName: "Manifest Details",
        importPath: "ShipFromStore/Manifest/ManifestDetails",
        component: "ManifestDetails",
        showOnMenu: false,
        default: false,
        isKeyCloak: true,
      },
      // {
      //   path: "/som/incentives",
      //   menuName: "Fullfilment Incentive",
      //   importPath: "Incentives/Incentives",
      //   component: "Incentives",
      //   showOnMenu: true,
      // },
    ],
  },
];
const PICK_FROM_STORE = [
  {
    path: "/som/pickFromStoreVerification",
    menuName: "Customer orders",
    importPath: "PickFromStore/ProcessDelivery/PickfromStoreVerification",
    component: "PickFromStoreVerification",
    showOnMenu: true,
    icon: "CustomerOrders",
  },
  {
    path: "/som/callCustomer",
    menuName: "Call Customer",
    importPath: "CallCustomers/CallCustomers",
    component: "CallCustomers",
    showOnMenu: true,
    icon: "CallCustomer",
  },
  {
    path: "/som/readyForInward",
    menuName: "Inward Order",
    importPath: "InwardOrder/ReadyForInward",
    component: "ReadyForInward",
    showOnMenu: true,
    icon: "InwardOrders",
  },
  {
    path: "/pickFromStoreHome",
    menuName: "Hidden route",
    importPath: "PickFromStore/PickFromStoreHome",
    component: "PickFromStoreHome",
    showOnMenu: false,
    isFullMenu: true,
  },
  {
    path: "/ProcessDelivery",
    menuName: "Hidden route",
    importPath: "PickFromStore/ProcessDelivery/ProcessDelivery",
    component: "ProcessDelivery",
    showOnMenu: false,
    isFullMenu: true,
  },
  {
    path: "/OrderListing",
    menuName: "Hidden route",
    importPath: "ProcessReturn/OrderListing",
    component: "OrderListing",
    showOnMenu: false,
    isFullMenu: true,
  },
  {
    path: "/IncompleteOrderProcessing",
    menuName: "Hidden route",
    importPath:
      "PickFromStore/IncompleteOrderProcessing/IncompleteOrderProcessing",
    component: "IncompleteOrderProcessing",
    showOnMenu: false,
    isFullMenu: true,
  },
  {
    path: "/taskLanding",
    menuName: "All Order",
    importPath: "TaskLanding",
    component: "TaskLanding",
    showOnMenu: false,
    isFullMenu: true,
  },

];
const DROP_AT_STORE = [
  // {
  //   path: "/som/returnOrder",
  //   menuName: "Return Orders",
  //   importPath: "ProcessReturn/ReturnOrdersHome",
  //   component: "ReturnOrders",
  //   showOnMenu: true,
  //   icon: "ReturnOrders",
  // },
  {
    path: "/som/returnOrders/page/returnSearchOrder",
    menuName: "Return Search Order",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: false,
  },
  {
    path: "/som/returnOrders/page/returnAcceptedOrder",
    menuName: "Return Accepted Order",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: false,
  },
  {
    path: "/som/returnOrders/page/processedReturnOrder",
    menuName: "Processed Return Order",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: false,
  },
  {
    path: "/som/createReturn/page/dropstoreSearchOrder",
    menuName: "Search Order",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: false,
  },
  {
    path: "/returnOrderSearch",
    menuName: "All Order",
    importPath: "ProcessReturn/OrderSearch",
    component: "OrderSearch",
    showOnMenu: false,
    isFullMenu: true,
  },
  {
    path: "/createReason",
    menuName: "All Order",
    importPath: "ProcessReturn/CreateReason",
    component: "CreateReason",
    showOnMenu: false,
    isFullMenu: true,
  },
  {
    path: "/saveQC",
    menuName: "All Order",
    importPath: "ProcessReturn/SaveQC",
    component: "SaveQC",
    showOnMenu: false,
    isFullMenu: true,
  },
  {
    path: "/refundSummary",
    menuName: "All Order",
    importPath: "RefundSummary/RefundSummary",
    component: "RefundSummary",
    showOnMenu: false,
    isFullMenu: true,
  },
  {
    path: "/shipmentListing",
    menuName: "All Order",
    importPath: "ProcessReturn/ShipmentListing",
    component: "ShipmentListing",
    showOnMenu: false,
    isFullMenu: true,
  },
];
const RETURNS = [];
const SUPERVISOR = [];
const ROD = [
  {
    path: "/som/RefundOnDemand",
    menuName: "Refund On Demand",
    importPath: "RefundOnDemand/RefundOnDemand",
    component: "RefundOnDemand",
    showOnMenu: true,
    icon: "ReturnOrders",
    default: false,
  }
];
const ROD_SUPERVISOR = [
  {
    path: "/som/RefundOnDemand",
    menuName: "Refund On Demand",
    importPath: "RefundOnDemand/RefundOnDemand",
    component: "RefundOnDemand",
    showOnMenu: true,
    icon: "ReturnOrders",
    default: false,
  },
  {
    path: "/som/HoldResoultion",
    menuName: "Hold Resolution",
    importPath: "RefundOnDemand/HoldResoultion",
    component: "HoldResoultion",
    showOnMenu: true,
    icon: "ReturnOrders",
    default: false,
  },
];
const FOC_CHILD = [
  {
    path: "/som/forcon",
    menuName: "Forward Consolidation",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "FOC",
    subMenu: [
      {
        path: "/som/forcon/forwardDownloadHoldOrders",
        menuName: "Download Hold Orders",
        importPath: "StoreOrder/ForwardDownloadHoldOrders",
        component: "IframeContainer",
        showOnMenu: true,
        default: false,
      },
      {
        path: "/som/forcon/StoreOrders",
        menuName: "FOC Store Orders",
        importPath: "StoreOrder/FocOrderForStore",
        component: "StoreOrder",
        showOnMenu: true,
        default: false,
        icon: "StoreIcon",
      },
      {
        path: "/som/forcon/STO",
        menuName: "STO",
        importPath: "StoreOrder/StockTransferOrder",
        component: "StoreOrder",
        showOnMenu: true,
        default: false,
        icon: "StoreIcon",
      },
      {
        path: "/som/forcon/ProceedToShortPick",
        menuName: "ProceedToShortPick",
        importPath: "StoreOrder/ProceedToShortPick",
        component: "StoreOrder",
        showOnMenu: false,
        default: false,
        icon: "StoreIcon",
      },
    ],
  },
];
const FOC_MASTER = [
  {
    path: "/som/forwCon/page/forwardDownloadHoldOrders",
    menuName: "Download Hold Orders",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "Download",
  },
  {
    path: "/som/forwCon1/page/forwardUploadGrnOrders",
    menuName: "Hold Resolve - Upload",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "Upload",
  },
];
const STORE_ADMIN = [
  {
    path: "/som/orders",
    menuName: "Store Supervision",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "StoreSuperVision",
    subMenu: [
      {
        path: "/som/orders/page/storeManagerForwardSerach",
        menuName: "Forward Orders",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true,
        default: false,
      },
      {
        path: "/som/orders/page/storeManagerReturnSerach",
        menuName: "Return Orders",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true,
      },
    ],
  },
  {
    path: "/som/configuration",
    menuName: "Node Configuration",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    expand: true,
    icon: "NodeConfig",
    subMenu: [
      {
        path: "/som/NodeSearchView",
        menuName: "Node Search View",
        importPath: "Admin/NodeSearchView/NodeSearchView",
        component: "NodeSearchView",
        icon: "YoutubeSearchedForIcon",
        showOnMenu: true,
      },
      {
        path: "/som/nodeCreation",
        menuName: "Node Creation",
        importPath: "Admin/NodeCreation/NodeCreation",
        component: "NodeCreation",
        icon: "AddIcon",
        showOnMenu: true,
      },
      {
        path: "/som/nodeStatus",
        menuName: "Enable/Disable",
        importPath: "Admin/NodeStatus/NodeStatus",
        component: "NodeStatus",
        icon: "ToggleOnIcon",
        showOnMenu: true,
      },
      {
        path: "/som/configuration/nodeCapacityUploadV2",
        menuName: "Node Capacity",
        importPath: "NodeConfiguration/NodeCapacityUploadV2",
        component: "NodeCapacityUploadV2",
        icon: "BarChartIcon",
        showOnMenu: true
      },
      {
        path: "/som/configuration/nodeTATUploadV2",
        menuName: "Node TAT",
        importPath: "NodeConfiguration/NodeTATUploadV2",
        component: "NodeTATUploadV2",
        icon: "BarChartIcon",
        showOnMenu: true
      },
      {
        path: "/som/nodeControlUpload",
        menuName: "Node Control",
        importPath: "Admin/NodeStatus/NodeControl",
        component: "NodeControl",
        icon: "VideogameAssetIcon",
        showOnMenu: true,
      },
      {
        path: "/som/configuration/nodeBulkNodePriorityUpload",
        menuName: "Bulk Node Priority",
        importPath: "NodeConfiguration/BulkNodePriority",
        component: "BulkNodePriority",
        icon: "LowPriorityIcon",
        showOnMenu: true
      },
      {
        path: "/som/configuration/nodeSafetyFactorUpload",
        menuName: "Node Safety Factor",
        importPath: "NodeConfiguration/NodeSafetyWOItem",
        component: "NodeSafetyWOItem",
        icon: "SecurityIcon",
        showOnMenu: true,
      },
      {
        path: "/som/configuration/nodeItemSafetyFactorUpload",
        menuName: "Node Item Safety Factor",
        importPath: "NodeConfiguration/NodeSafetyFactor",
        component: "NodeSafetyFactor",
        icon: "SecurityOutlinedIcon",
        showOnMenu: true,
      },
      {
        path: "/som/configuration/globalSafetyFactorUpload",
        menuName: "Global Safety Factor",
        importPath: "NodeConfiguration/GlobalSafetyFactor",
        component: "GlobalSafetyFactor",
        icon: "VpnLockIcon",
        showOnMenu: true,
      },
      {
        path: "/som/Migration/storeMigration",
        menuName: "SOM Migration",
        importPath: "Admin/Migration/StoreMigration",
        component: "storeMigration",
        icon: "SyncAltRoundedIcon",
        showOnMenu: true,
      },
      {
        path: "/som/orderRescheduling",
        menuName: "Order Rescheduling",
        importPath: "Admin/orderRescheduling/OrderRescheduling",
        component: "OrderRescheduling",
        icon: "ReorderIcon",
        showOnMenu: true,
      },
      {
        path: "/som/manualOverrideOfPDD",
        menuName: "Manual Override Of PDD",
        importPath: "Admin/manualOverrideOfPDD/ManualOverrideOfPDD",
        component: "ManualOverrideOfPDD",
        icon: "PlaylistAddIcon",
        showOnMenu: true,
      },
      {
        path: "/som/orderReschedulingDC",
        menuName: "DC Order Rescheduling",
        importPath: "Admin/orderReschedulingDC/OrderReschedulingDC",
        component: "OrderReschedulingDC",
        icon: "ReorderIcon",
        showOnMenu: true,
      },
    ],
  },
];
const COMMON_NAV = [{
  path: "/som/helpSection",
  menuName: "Help Section",
  importPath: "Help/HelpSection",
  component: "HelpSection",
  icon: "HelpIcon",
  showOnMenu: false,
}];
export {
  PICKER_PACKER_MANIFEST_TRENDS,
  PICKER_PACKER_MANIFEST_SUPERVISOR,
  PICKER_PACKER_MANIFEST_NONTRENDS,
  PICK_FROM_STORE,
  DROP_AT_STORE,
  RETURNS,
  SUPERVISOR,
  ROD,
  ROD_SUPERVISOR,
  FOC_CHILD,
  FOC_MASTER,
  STORE_ADMIN,
  COMMON_NAV
};
