const storeAdminMenu = [
  {
    path: "/som/orders",
    menuName: "Store Supervision",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "StoreSuperVision",
    subMenu: [
      {
        path: "/som/orders/page/storeManagerForwardSerach",
        menuName: "Forward Orders",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true,
        default: false
      },
      {
        path: "/som/orders/page/storeManagerReturnSerach",
        menuName: "Return Orders",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true
      }
    ]
  },
  {
    path: "/som/configuration",
    menuName: "Node Configuration",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "NodeConfig",
    subMenu: [
      {
        path: "/som/NodeSearchView",
        menuName: "Node Search View",
        importPath: "Admin/NodeSearchView/NodeSearchView",
        component: "NodeSearchView",
        icon: "YoutubeSearchedForIcon",
        showOnMenu: true
      },
      {
        path: "/som/nodeCreation",
        menuName: "Node Creation",
        importPath: "Admin/NodeCreation/NodeCreation",
        component: "NodeCreation",
        icon: "AddIcon",
        showOnMenu: true
      },
      {
        path: "/som/nodeStatus",
        menuName: "Enable/Disable",
        importPath: "Admin/NodeStatus/NodeStatus",
        component: "NodeStatus",
        icon: "ToggleOnIcon",
        showOnMenu: true
      },
      {
        path: "/som/configuration/page/nodeCapacityUpload",
        menuName: "Capacity",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        icon: "BarChartIcon",
        showOnMenu: true,
        default: false
      },
      // {
      //   path: "/som/configuration/page/nodeControlUpload",
      //   menuName: "Control",
      //   importPath: "IframeContainer/IframeContainer",
      //   component: "IframeContainer",
      //   showOnMenu: true
      // },
      {
        path: "/som/nodeControlUpload",
        menuName: "Node Control",
        importPath: "Admin/NodeStatus/NodeControl",
        component: "NodeControl",
        icon: "VideogameAssetIcon",
        showOnMenu: true
      },
      {
        path: "/som/configuration/nodeBulkNodePriorityUpload",
        menuName: "Bulk Node Priority",
        importPath: "NodeConfiguration/BulkNodePriority",
        component: "BulkNodePriority",
        icon: "LowPriorityIcon",
        showOnMenu: true
      },
      {
        path: "/som/configuration/nodeSafetyFactorUpload",
        menuName: "Node Safety Factor",
        importPath: "NodeConfiguration/NodeSafetyWOItem",
        component: "NodeSafetyWOItem",
        icon: "SecurityIcon",
        showOnMenu: true
      },
      {
        path: "/som/configuration/nodeItemSafetyFactorUpload",
        menuName: "Node Item Safety Factor",
        importPath: "NodeConfiguration/NodeSafetyFactor",
        component: "NodeSafetyFactor",
        icon: "SecurityOutlinedIcon",
        showOnMenu: true
      },
      {
        path: "/som/configuration/globalSafetyFactorUpload",
        menuName: "Global Safety Factor",
        importPath: "NodeConfiguration/GlobalSafetyFactor",
        component: "GlobalSafetyFactor",
        icon: "VpnLockIcon",
        showOnMenu: true
      },
      {
        path: "/som/dryRunTool",
        menuName: "Dry Run Tool",
        importPath: "Admin/DryRunTool/DryRunTool",
        component: "DryRunTool",
        icon: "BuildIcon",
        showOnMenu: true
      },
      {
        path: "/som/orderRescheduling",
        menuName: "Order Rescheduling",
        importPath: "Admin/orderRescheduling/OrderRescheduling",
        component: "OrderRescheduling",
        icon: "ReorderIcon",
        showOnMenu: true,
      },
      {
        path: "/som/manualOverrideOfPDD",
        menuName: "Manual Override Of PDD",
        importPath: "Admin/manualOverrideOfPDD/ManualOverrideOfPDD",
        component: "ManualOverrideOfPDD",
        icon: "ReorderIcon",
        showOnMenu: true,
      },
      {
        path: "/som/orderReschedulingDC",
        menuName: "DC Order Rescheduling",
        importPath: "Admin/orderReschedulingDC/OrderReschedulingDC",
        component: "OrderReschedulingDC",
        icon: "ReorderIcon",
        showOnMenu: true,
      },
    ]
  }
];

export default storeAdminMenu;
