export default [
  {
    path: "/som/shipFromStore",
    menuName: "Ship From Store",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "ShipfromStore",
    subMenu: [
      {
        path: "/som/shipFromStore/page/searchOrder",
        menuName: "Search Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true,
        default: false
      },
      {
        path: "/som/shipFromStore/page/openOrders",
        menuName: "Open Orders",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true
      },
      {
        path: "/som/shipFromStore/page/acknowledgeOrders",
        menuName: "Accepted orders",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true
      },
      {
        path: "/som/shipFromStore/page/packedOrders",
        menuName: "Packed orders",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true
      },
      {
        path: "/som/shipFromStore/page/orderManifest",
        menuName: "Close manifest",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true
      }
    ]
  },
  // {
  //   path: "/som/pickFromStoreVerification",
  //   menuName: "Customer orders",
  //   importPath: "PickFromStore/ProcessDelivery/PickfromStoreVerification",
  //   component: "PickFromStoreVerification",
  //   showOnMenu: true,
  //   icon: "CustomerOrders"
  // },
  {
    path: "/som/returnOrder",
    menuName: "Return Orders",
    importPath: "ProcessReturn/ReturnOrdersHome",
    component: "ReturnOrders",
    showOnMenu: true,
    icon: "ReturnOrders"
  },
  // {
  //   path: "/som/readyForInward",
  //   menuName: "Inward Order",
  //   importPath: "InwardOrder/ReadyForInward",
  //   component: "ReadyForInward",
  //   showOnMenu: true,
  //   icon: "InwardOrders"
  // },
  {
    path: "/som/returnOrders",
    menuName: "Return orders",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: false,
    icon: "ReturnOrders",
    subMenu: [
      {
        path: "/som/returnOrders/page/returnSearchOrder",
        menuName: "Return Search Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: false
      },
      {
        path: "/som/returnOrders/page/returnAcceptedOrder",
        menuName: "Return Accepted Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: false
      },
      {
        path: "/som/returnOrders/page/processedReturnOrder",
        menuName: "Processed Return Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: false
      }
    ]
  },
  {
    path: "/som/createReturn",
    menuName: "Create return",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: false,
    icon: "ViewModuleIcon",
    subMenu: [
      {
        path: "/som/createReturn/page/dropstoreSearchOrder",
        menuName: "Search Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true
      }
    ]
  }
  // {
  //   path: "/som/callCustomer",
  //   menuName: "Call Customer",
  //   importPath: "CallCustomers/CallCustomers",
  //   component: "CallCustomers",
  //   showOnMenu: true,
  //   icon: "CallCustomer"
  // }
];