import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ComponentRoutes from "../../../helpers/ComponentRoutes/ComponentRoutes";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link, withRouter } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  getUsertype,
  getStoreNode,
  getUserName,
  getStoreOrigin,
  logout,
  getRoles,
  isLoggedIn
} from "../../../helpers/Utility";
import getIcon from "../../../configs/iconCollection";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import { PubSub } from 'pubsub-js';
import pathMap from "../../../configs/pathMap";
import { getMenuForRespectiveRole } from "../../../menuConfiguration";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  list: {
    width: 240
  },
  fullList: {
    width: "auto"
  },
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  highLight: {
    background: "#293847",
    borderLeft: "5px solid #00ab8d"
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    overflowX: "hidden"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  root: {
    display: "flex"
  },
  listTextHeader: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "white"
  },
  listText: {
    fontSize: "13px",
    color: "white"
  },
  listTextSingle: {
    fontSize: "14px",
    color: "white",
    fontWeight: "bold"
  },
  itemIcon: {
    minWidth: "40px",
    color: "white"
  },
  itemTextRoot: {
    marginLeft: "25px"
  },
  itemTextRootSubMenu: {
    marginLeft: "0px"
  },
  toolbarRoot: {
    background: "white",
    color: "black"
  }
}));

function SOMAppDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { container } = props;
  const [subMenuOpen, setSubMenuOpen] = React.useState("");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const [refresh, setRefresh] = React.useState(true);
  // const [frameUrl, setFrameUrl] = React.useState('');

  // Handlers to open and close Menu(username and storenode display on Topbar) in mobile screens.
  const handleUserMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

    // Logic to open Default link on '/som' or '/som/'
    let defaultLink = "";
    let redirectLink = "";
    for (let i = 0; i < menu.length; i++) {
      if (typeof menu[i].default !== "undefined" && menu[i].default) {
        // defaultLink = menu[i].path;
        redirectLink = menu[i].path;
        defaultLink = menu[i].path;
      } else {
        if (menu[i].subMenu) {
          for (let j = 0; j < menu[i].subMenu.length; j++) {
            if (!!menu[i].subMenu[j].default) {
              // defaultLink = menu[i].subMenu[j].path;
              defaultLink = menu[i].path;
              redirectLink = menu[i].subMenu[j].path;
            }
          }
        }
      }
    }
    // console.log("Default link " + props.location.pathname);
    let location =
      props.location && props.location.pathname
        ? props.location.pathname
        : "/som/home";

    if (redirectLink) {
      if (location === "/som/home" || location === "/som/home/") {
        setSubMenuOpen(defaultLink);
        if (defaultLink !== "") {
          props.history.replace(redirectLink);
        }
      }
    }
    else {
      let expandLink = menu.filter(eachMainMenu => {
        if (eachMainMenu.subMenu) {
          for (let k = 0; k < eachMainMenu.subMenu.length; k++) {
            if (eachMainMenu.subMenu[k].path === location) {
              return true;
            } else {
              continue;
            }
          }
        }
      });
      if (expandLink.length > 0) {
        setSubMenuOpen(expandLink[0]["path"]);
      } else {
        menu.filter(eachMainMenu => {
          if (eachMainMenu.expand === true) {
            setSubMenuOpen(eachMainMenu.path);
          }
        })
        props.history.replace(location);
      }
    }

    // PubSub.subscribe('TOPIC_APP_DRAWER', setUrl.bind(this));

    return () => {
      // When cleanup is called, toggle the mounted variable to false
      // PubSub.unsubscribe("TOPIC_APP_DRAWER");
    };
  }, []);

  // Assign Menu items based on USER ROLE.
  const getDrawerMenu = () => {
    const userType = getUsertype();
    let menu = getMenuForRespectiveRole(userType);

    return menu;
  };

  const getKeyCloakBasedMenu = () => {
    let keyCloakRole = getRoles();
    let menu = getMenuForRespectiveRole(keyCloakRole);

    return menu;
  }

  const menu = getKeyCloakBasedMenu();

  const getTitle = () => {
    const userType = getUsertype();
    let title = '';
    switch (userType) {
      case "REFUND_ON_DEMAND_SUP":
        title = "Refund On Demand"
        break;
      case "REFUND_ON_DEMAND":
        title = "Refund On Demand"
        break;
      default:
        title = "Store Order Management"
        break;
    }

    return title;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const setUrl = (msg, url) => {
  //   console.log('----------url');
  //   console.log(url);
  //   setFrameUrl(url);
  // }

  // const getMatchingPath = (menu) =>{
  //   let path = "";

  //   if(menu.secondaryPath){
  //     menu.secondaryPath.map(each => {
  //       if (frameUrl.search(each) > 0) {
  //         console.log(frameUrl);
  //         console.log(menu.secondaryPath);
  //         console.log(menu);
  //         path = menu.path;
  //       }
  //     })
  //   } else {
  //     path = menu.path;
  //   }
  //   console.log(props.location.pathname === path);
  //   return props.location.pathname === path;
  // }

  const checkMenuPath = (menu) => {
    return props.location.pathname === menu.path;
  }

  const drawer = (
    <div
      className={classes.list}
    // role="presentation"
    // onClick={toggleDrawer(side, false)}
    // onKeyDown={handleDrawerToggle}
    >
      <img
        src="/ajio-logo.png"
        style={{
          margin: "17px",
          paddingBottom: 0,
          width: "100px"
        }}
      />
      <Divider />
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{
          flexGrow: 1
        }}
      >
        {menu.map((eachMenu, index) => {
          return (
            <React.Fragment key={index + 'mainFragment'}>
              {eachMenu.showOnMenu &&
                eachMenu.subMenu &&
                eachMenu.subMenu.length > 0 ? (

                  // Multiple Menu Items with Expand and Collapse --- STARTS
                  <React.Fragment key='subFragment' >
                    <ListItem
                      key={eachMenu.path + index + 'eachItem'}
                      button
                      onClick={e => {
                        if (subMenuOpen !== "" && eachMenu.path === subMenuOpen) {
                          setSubMenuOpen("");
                        } else {
                          setSubMenuOpen(eachMenu.path);
                        }
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.itemIcon }}>
                        {getIcon(eachMenu.icon)}
                      </ListItemIcon>
                      <ListItemText
                        primary={eachMenu.menuName}
                        classes={{ primary: classes.listTextHeader }}
                      />
                      {subMenuOpen && subMenuOpen.split("/page/")[0] === eachMenu.path ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                          <ExpandMore style={{ color: "white" }} />
                        )}
                    </ListItem>

                    {/* List of Nested Menu Items -- Looping through subMenu --- STARTS */}
                    <Collapse
                      key={eachMenu.path + index + 'collapse'}
                      in={
                        subMenuOpen &&
                          subMenuOpen.split("/page/")[0] === eachMenu.path ? true : false
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      {eachMenu.subMenu.map((eachSubMenu, index) => (
                        eachSubMenu.showOnMenu && (
                          <List
                            key={eachSubMenu.path + index + 'list'}
                            component="div"
                            disablePadding
                          >
                            <Link
                              key={eachSubMenu.path + index + 'link'}
                              to={{
                                pathname: eachSubMenu.path
                              }}
                              style={{
                                color: "black",
                                textDecoration: "none"
                              }}
                              replace
                            >

                              <ListItem
                                key={eachSubMenu.path + index + 'individualItem'}
                                button
                                className={checkMenuPath(eachSubMenu) ? classes.highLight : ''}
                                style={{
                                  paddingLeft: checkMenuPath(eachSubMenu) ? 30 : 35
                                }}
                                onClick={() => {
                                  // To reload the iframe container on click to selected menu
                                  if (checkMenuPath(eachSubMenu)) {
                                    var iframe = document.getElementById("iframe_id");
                                    if (iframe) {
                                      iframe.src = pathMap[props.location.pathname.split("/page/")[1]]['link'];
                                    }
                                  }

                                  // To close the drawer on selecting menu item in mobile view
                                  if (mobileOpen) {
                                    handleDrawerToggle();
                                  }
                                }}
                              >
                                <ListItemIcon classes={{ root: classes.itemIcon }}>
                                  {getIcon(eachSubMenu.icon)}
                                </ListItemIcon>
                                <ListItemText
                                  primary={eachSubMenu.menuName}
                                  classes={{
                                    primary: classes.listText,
                                    root: classes.itemTextRootSubMenu
                                  }}
                                  style={
                                    checkMenuPath(eachSubMenu)
                                      ? { marginLeft: "0px" }
                                      : {}
                                  }
                                />
                              </ListItem>
                            </Link>
                          </List>
                        )))}
                    </Collapse>
                    {/* List of Nested Menu Items -- Looping through subMenu --- ENDS */}


                  </React.Fragment>
                  // Multiple Menu Items with Expand and Collapse --- ENDS

                ) : (
                  // Single Menu Items without Expand and Collapse --- STARTS
                  <React.Fragment>
                    {eachMenu.showOnMenu && (
                      <Link
                        to={{
                          pathname: eachMenu.path
                        }}
                        style={{
                          color: "black",
                          textDecoration: "none"
                        }}
                        replace
                        key={eachMenu.path + index + 'linkItem'}
                      >
                        <ListItem
                          button
                          key={eachMenu.path + index + 'listItem'}
                          className={checkMenuPath(eachMenu) ? classes.highLight : ''}
                          onClick={() => {
                            // To reload the iframe container on click to selected menu
                            if (checkMenuPath(eachMenu)) {
                              var iframe = document.getElementById("iframe_id");
                              if (iframe) {
                                iframe.src = pathMap[props.location.pathname.split("/page/")[1]]['link'];
                              }
                            }

                            // To close the drawer on selecting menu item in mobile view
                            if (mobileOpen) {
                              handleDrawerToggle();
                            }
                          }}
                        >
                          <ListItemIcon
                            classes={{ root: classes.itemIcon }}
                            style={checkMenuPath(eachMenu) ? { marginLeft: "-5px" } : {}}
                          >
                            {getIcon(eachMenu.icon)}
                          </ListItemIcon>
                          <ListItemText
                            primary={eachMenu.menuName}
                            classes={{ primary: classes.listTextSingle }}
                          />
                        </ListItem>
                      </Link>
                    )}
                  </React.Fragment>
                  // Single Menu Items without Expand and Collapse --- ENDS                
                )}
            </React.Fragment>
          );
        })}
     
        {/* Return POD Manifest - Feature - 233116 -  Menu Item -- STARTS */}
        <ListItem
          button
          key="returnpod"
          onClick={() => {
            // let userOrigin = getStoreOrigin();
           // logout();
            // props.history.replace('/login');
            // window.location.href = `/login.html?origin=${userOrigin}`;
            //const link = "https://tms.jio.com/console/ajio/som/";
            const link = "https://som.services.ajio.com/manifest/";
            window.open(link,'_blank');
          }}
        >
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            {getIcon("LinkIcon")}
          </ListItemIcon>
          <ListItemText
            primary="Manifest - Return POD"
            classes={{ primary: classes.listTextSingle }}
          />
        </ListItem>
        {/* Return POD Manifest - Feature - 233116  -- ENDS */}
        
         {/* Swaddle Return - STARTS */}
        <ListItem
          button
          key="swaddlereturn"
          onClick={() => {
            const link = "https://swaddle-returns.ajio.jio.com/";
            window.open(link,'_blank');
          }}
        >
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            {getIcon("LinkIcon")}
          </ListItemIcon>
          <ListItemText
            primary="Swaddle - Returns"
            classes={{ primary: classes.listTextSingle }}
          />
        </ListItem>
        {/* Swaddle Return - ENDS */}

        {/* Help Section - Feature - 250452 -  Menu Item -- STARTS */}
        <ListItem
          button
          key="helpsection"
          onClick={() => {
            let userOrigin = getStoreOrigin();
            let url = "/index.html?origin="+userOrigin;
            window.location.href = url+"#/som/HelpSection"
          }}
        >
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            {getIcon("HelpIcon")}
          </ListItemIcon>
          <ListItemText
            primary="Store Contact Update & Help Section"
            classes={{ primary: classes.listTextSingle }}
          />
        </ListItem>
        {/* Help Section - Feature - 250452  -- ENDS */}

        {/* Logout Menu Item -- STARTS */}
        <ListItem
          button
          key="logout"
          onClick={() => {
            // let userOrigin = getStoreOrigin();
            logout();
            props.history.replace('/login');
            // window.location.href = `/login.html?origin=${userOrigin}`;
          }}
        >
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            {getIcon("Logout")}
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            classes={{ primary: classes.listTextSingle }}
          />
        </ListItem>
        {/* Logout Menu Item -- ENDS */}

      </List>
    </div>
  );

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />

        {/* APPBAR (TOPBAR - WHITE) --- STARTS */}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarRoot }}>

            {/* Menu Icon which is visible in mobile screens -- STARTS */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {/* Menu Icon which is visible in mobile screens -- ENDS */}

            <Typography
              variant="h6"
              noWrap
              style={{
                flexGrow: 1
              }}
            >
              {getTitle()}
            </Typography>

            {/* Display User name and Store node for Large Screen -- STARTS */}
            <Hidden xsDown implementation="css">
              <div>
                <p style={{ margin: 0 }}>
                  User <b>{getUserName()}</b>
                </p>
                <p style={{ margin: 0 }}>
                  Store <b>{getStoreNode()}</b>
                </p>
              </div>
            </Hidden>
            {/* Display User name and Store node for Large Screen -- ENDS */}

            {/* Display User name and Store node for Small Screen -- STARTS */}
            <Hidden smUp implementation="css">
              <div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleUserMenuClick}
                >
                  <MoreVertIcon style={{ color: "black" }} />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleUserMenuClose}
                >
                  <MenuItem key="user">
                    User : <b>{" " + getUserName()}</b>
                  </MenuItem>
                  <MenuItem key="store">
                    Store: <b>{" " + getStoreNode()}</b>
                  </MenuItem>
                </Menu>
              </div>
            </Hidden>
            {/* Display User name and Store node for small Screen -- ENDS */}

          </Toolbar>
        </AppBar>
        {/* APPBAR (TOPBAR - WHITE) --- ENDS */}

        <nav className={classes.drawer} aria-label="mailbox folders">

          {/* Left Drawer for Mobile screens --- STARTS */}
          <Hidden mdUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          {/* Left Drawer for Mobile screens --- ENDS */}

          {/* Left Drawer for Desktop and laptop screens --- STARTS */}
          <Hidden mdDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
          {/* Left Drawer for Desktop and laptop screens --- ENDS */}

        </nav>

        {/* Main Container to load all the components --- STARTS */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {isLoggedIn() ? <ComponentRoutes menu={menu} /> : props.history.replace('/login')}
        </main>
        {/* Main Container to load all the components --- ENDS */}

      </div>
    </React.Fragment>
  );
}

SOMAppDrawer.propTypes = {
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  )
};

export default withRouter(SOMAppDrawer);
