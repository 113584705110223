import React, { Component } from "react";

const AsyncLoader = importComponent => {
  return class extends Component {
    _isMounted = false;
    state = {
      component: null
    };

    componentDidMount() {
      this._isMounted = true;
      importComponent().then(cmp => {
        if (this._isMounted) {
          this.setState({ component: cmp.default });
        }
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : null;
    }
  };
};

export default AsyncLoader;
