const refundOnDemandMenu = [
    {
        path: "/som/RefundOnDemand",
        menuName: "Refund On Demand",
        importPath: "RefundOnDemand/RefundOnDemand",
        component: "RefundOnDemand",
        showOnMenu: true,
        icon: "ReturnOrders",
        default: false
    }
  ]
  
  export default refundOnDemandMenu;