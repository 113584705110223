import { authToken } from "../helpers/Utility.js";

export default function logError(methodType, originalURL, payload, supportID) {
  fetch(`/dashboard/v1/supportTicket`, {
    method: 'POST',
    headers: {
      "x-auth-token": authToken()
    },
    body: JSON.stringify({
      originalURL,
      methodType,
      payload,
      supportID
    })
  })
    .then(data => console.log(`WAF logged successfully for ${originalURL}`))
    .catch(error => console.error('An error occurred while logging WAF error'));
}
