import React, { Component, createContext } from "react";

export const CustomerContext = createContext();

class CustomerContextProvider extends Component {
  state = {
    mobileNumber: localStorage.getItem("num") || "",
    userName: null,
    password: null,
    userList: []
  };

  setMobileNum = num => {
    localStorage.setItem("num", num);
    this.setState({ mobileNumber: num });
  };

  setName = name => {
    this.setState({ userName: name });
  }

  setPassword = pass => {
    this.setState({ password: pass });
  }

  setUserList = list => {
    this.setState({ userList: list })
  }

  render() {
    return (
      <CustomerContext.Provider
        value={{ ...this.state, setMobileNum: this.setMobileNum, setName: this.setName, setPassword: this.setPassword, setUserList: this.setUserList }}
      >
        {this.props.children}
      </CustomerContext.Provider>
    );
  }
}

export default CustomerContextProvider;
