import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { BackOption } from "../../helpers/Utility";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CustomerContext } from "../../contexts/CustomerContext";
import { withRouter, Redirect } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  bottomFixed: {
    position: "fixed",
    bottom: "15px",
    right: "20px"
  },
  link: {
    margin: theme.spacing(1),
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: 15,
    color: "#176c93"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const CustomerInfo = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <CustomerContext.Consumer>
      {context => {
        const { mobileNumber } = context;
        const { goLink } = props;
        return (
          <>
            {
              mobileNumber !== "" ? (
                  <>
                    <Typography variant="h6" className={classes.title}>
                      {props.header}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                      Mobile Number <b>{"+91 " + mobileNumber}</b>
                      <a className={classes.link} onClick={handleClickOpen}>
                        Change Customer
                </a>
                    </Typography>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Are you sure you want to change customer?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          You will lose the information entered if you change customer.
                    <br />
                          Mobile <b>{mobileNumber}</b>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          cancel
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => {
                            if (goLink)
                              props.history.replace(goLink);
                            else
                              props.history.replace('/som');
                          }}
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
              ) : (
                <Redirect to={props.redirect} exact />
              )
            }
          </>
        );
      }}
    </CustomerContext.Consumer>
  );
};

CustomerInfo.defaultProps = {
  redirect: '/som'
}

export default withRouter(CustomerInfo);
