import React from "react";
import menu from "../../configs/fullScreenMenu/nodeControlMenu";
import AsyncLoader from "../async/AsyncLoader";
import { Route, Redirect, withRouter, HashRouter } from "react-router-dom";
import { isLoggedIn, getRoles } from "../Utility";
import DefaultLandingPage from "../../modules/common/DefaultLandingPage";
import ComponentRoutes from "../ComponentRoutes/ComponentRoutes";
import { getMenuForRespectiveRole } from "../../menuConfiguration";

class RoutingUtility extends React.Component {
  constructor(props) {
    super(props);
    this.protectedRouterList = [];
    this.componentsImport = {};
    this.routerListItem = [];
    let usertype = localStorage.getItem("user_type");
    let keyCloakRole = getRoles();
    this.routerListItem = getMenuForRespectiveRole(keyCloakRole);
    this.getOnlyFullMenus();
  }

  getOnlyFullMenus = () => {
    this.routerListItem.forEach((ele, index) => {
      if (ele.subMenu) {
        ele.subMenu.forEach((element, eleIndex) => {
          this.checkFullMenu(element, eleIndex);
        });
      } else {
        this.checkFullMenu(ele, index);
      }
    });
    console.log('FULL MENU ITEMS --------- ' + this.protectedRouterList);
  }

  checkFullMenu = (element, eleIndex) => {
    if (element.isFullMenu) {
      this.protectedRouterList.push(element);
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* {this.protectedRouterList.length === 0 ? this.createProtectedList() : this.protectedRouterList} */}
        {isLoggedIn() ? (
          <>
            <ComponentRoutes menu={this.protectedRouterList} />
          </>
        ) : <Redirect to='/login' />}
      </React.Fragment>
    );
  }
}

export default RoutingUtility;
