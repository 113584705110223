import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TrackOrderTopBar from "../Components/TrackOrderTopBar/TrackOrderTopBar";
import { Link, withRouter } from "react-router-dom";
import trackOrderMenu from "../../../configs/trackOrderMenu";
import ComponentRoutes from "../../../helpers/ComponentRoutes/ComponentRoutes";
import CustomerInfo from "../../common/CustomerInfo";
import Hidden from "@material-ui/core/Hidden";
import http from "../../../apis/http";
import { authToken, getStoreNode } from "../../../helpers/Utility";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { PubSub } from 'pubsub-js';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#2f4051",
    color: "white"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    margin: "0px 15px"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: {
    minHeight: "48px"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#2f4051"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  highlight: {
    borderLeft: "5px solid #00ab8d"
  },
  progress: {
    position: "absolute",
    marginTop: -15,
    marginLeft: -15
  },
  fontBold: {
    fontWeight: "bold"
  }
}));

const MENU_MAP = {
  ReadyForPickUpOrder: "ready_for_pickUp",
  ReadyForInward: "ready_for_inward",
  TrackOnTheWayOrder: "on_the_way"
};

function AppDrawer(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [noOfOrders, setNoOfOrders] = React.useState({
        ready_for_pickUp: 0,
        ready_for_inward: 0,
        on_the_way: 0
    });
    const [ordersCountLoading, setOrdersCountLoading] = React.useState(true);
    const customerContext = React.useContext(CustomerContext);
    var mounted = true;
    var pubsub;
    
    function getCount() {
        setOrdersCountLoading(true);
        http.get(`/dashboard/v1/get_api?option=COUNT&store_node=${getStoreNode()}&phone_number=${customerContext.mobileNumber}`, {
            headers: {
                'x-auth-token': authToken(),
                'x-requested-by': 123
            }
        })
            .then(
                (rsp) => {
                    if (mounted) {
                        setNoOfOrders(rsp.data);
                        console.log(noOfOrders);
                        setOrdersCountLoading(false);
                    }
                }, err => {
                    console.log(err);
                    setOrdersCountLoading(false);
                }
            )
            .catch(() => {
                console.log("Catch Block");
                setOrdersCountLoading(false);
            });
    }

    React.useEffect(() => {        
        getCount();
        pubsub = PubSub.subscribe('TOPIC_COUNT_UPDATE', countUpdate.bind(this));

        return () => {
            // When cleanup is called, toggle the mounted variable to false
            PubSub.unsubscribe("TOPIC_COUNT_UPDATE");
            mounted = false;
        };
    }, []);

    const countUpdate = (msg, data) => {
        if (data) {
            getCount();
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

  const listItems = (
    <List key="list">
      {trackOrderMenu.map((eachMenu, index) => (
        <React.Fragment>
          {eachMenu.showOnMenu && (
            <Link
              to={{
                pathname: eachMenu.path
              }}
              style={{
                color: "white",
                textDecoration: "none"
              }}
              replace
              onClick={() => getCount()}
            >
              <ListItem
                button
                classes={{
                  root:
                    props.location.pathname === eachMenu.path
                      ? classes.highlight
                      : ""
                }}
                key={eachMenu.menuName}
                selected={props.location.pathname === eachMenu.path}
              >
                <ListItemText
                  classes={{
                    primary:
                      props.location.pathname === eachMenu.path
                        ? classes.fontBold
                        : ""
                  }}
                  primary={eachMenu.menuName}
                />
                <div key={eachMenu.component}>
                  {ordersCountLoading ? (
                    <CircularProgress
                      color="white"
                      className={classes.progress}
                      style={{
                        width: "25px",
                        height: "25px"
                      }}
                    />
                  ) : (
                    noOfOrders[MENU_MAP[eachMenu.component]] || ""
                  )}
                </div>
              </ListItem>
            </Link>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <TrackOrderTopBar title="Track Order">
            <CustomerInfo
              header=""
              goLink="/som/pickFromStoreVerification"
              redirect="/som/pickFromStoreVerification"
            ></CustomerInfo>
          </TrackOrderTopBar>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            <div className={classes.drawerHeader}>
              <img src="/ajio-logo.png" />
            </div>
            <Divider />
            {listItems}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            <div className={classes.drawerHeader}>
              <img src="/ajio-logo.png" />
            </div>
            <Divider />
            {listItems}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.toolbar} />
        <div className={classes.toolbar} />
        <ComponentRoutes menu={trackOrderMenu} />
      </main>
    </div>
  );
}

export default withRouter(AppDrawer);
