import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: "#fff",
    margin: 0,
    height: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },
  innerWrapper: {
    display: "flex",
    color: "#b7b7b7",
    fontSize: "42px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: 'center'
  }
}));

const DefaultLandingPage = props => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <h1 className={classes.innerWrapper}>
        Welcome to Store Order Management
      </h1>
    </div>
  );
};
export default withSnackbar(DefaultLandingPage);
