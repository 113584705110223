import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  topBarPaper: {
    boxShadow: "none",
    borderRadius: "unset",
    padding: theme.spacing(1),
    width: "100%"
  },
  topBarDiv: {
    display: "inline-flex"
  },
  topBarTypography: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(2)
  },
  icon: {
    margin: "4px 10px",
    cursor: "pointer",
    position: 'absolute',
    fontSize: "30px"
  },
  childDiv: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4)
  }
}));

function TrackOrderTopBar(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper classes={{ root: classes.topBarPaper }}>
        <div className={classes.topBarDiv}>
          <ArrowBackIosIcon
            fontSize='large'
            classes={{ root: classes.icon }}
            onClick={() => {
              props.history.replace("/pickFromStoreHome");
            }}
          />
          {/* {props.children} */}
          <Typography variant="h6" classes={{ root: classes.topBarTypography }}>
            {props.title}
          </Typography>
        </div>
        <div className={classes.childDiv}>{props.children}</div>
      </Paper>
    </React.Fragment>
  );
}

export default withRouter(TrackOrderTopBar);
