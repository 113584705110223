import config from "./configs";

export const KEYCLOAK = {
  clientID: "ajiosom-ui",
  clientSecret: config.REACT_APP_CLIENT_SECRET_KEY,
  realm: "ajiosom",
  tenant: "AJIOSOM",
};

export const STATUS = {
  CREATED: "Created",
  OPEN: "Open",
  PACK_IN_PROGRESS: "Pack in Progress",
  IN_PROGRESS: "In Progress",
  PACKED: "Packed",
  SHIPPED: "Shipped",
  CANCELLED: "Cancelled",
  READY_TO_PACK: "Ready To Pack",
  UNWAVED: "Unwaved",
  WAVED: "Waved",
  PICKED: "Picked",
  PENDING_RESOLUTION: "Pending Resolution",
  READY_TO_CANCEL: "Ready To Cancel",
  MANIFESTED: "Manifested",
};

export const GROUPS = ["TRENDS"];
