import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AsyncLoader from "../async/AsyncLoader";
import ProtectedRoutes from "../ProtectedRoutes/ProtectedRoutes";
import DefaultLandingPage from "../../modules/common/DefaultLandingPage";
import { Route } from "react-router-dom";

class ComponentRoutes extends Component {
  constructor(props) {
    super(props);
    this.protectedRouterList = [];
    this.componentsImport = {};
  }

  addAsyncComponentImport = (leafChild, index) => {
    /* Create List of Async-import-objects */
    if (leafChild.component !== "" && leafChild.importPath !== "") {
      const tempImport = {
        [leafChild.component]: AsyncLoader(() =>
          import(`../../modules/${[leafChild.importPath]}`)
        )
      };
      this.componentsImport = Object.assign(this.componentsImport, tempImport);

      /** Create List of ProtectedRouter */
      if (leafChild.path !== "") {
        this.protectedRouterList.push(
          <ProtectedRoutes
            id={leafChild.component + index}
            key={leafChild.path + index + 'components'}
            path={leafChild.path}
            component={this.componentsImport[leafChild.component] || ""}
            isKeyCloak={leafChild.isKeyCloak}
            {...this.props}
          />
        );
      }
    } else {
      console.log(leafChild);
    }
  };

  createProtectedList = () => {
    this.protectedRouterList = [];
    const { menu } = this.props;

    menu.forEach((ele, index) => {
      if (ele.subMenu) {
        ele.subMenu.forEach((element, eleIndex) => {
          this.addAsyncComponentImport(element, eleIndex);
        });
      } else {
        this.addAsyncComponentImport(ele, index);
      }
    });
    // console.log(this.protectedRouterList);
    return this.protectedRouterList;
  };

  render() {
    return (
      <>
        {this.protectedRouterList.length === 0
          ? this.createProtectedList()
          : this.protectedRouterList}
        <Route path={"/som/home"} component={DefaultLandingPage} />
      </>
    );
  }
}

export default withRouter(ComponentRoutes);
