import { getStoreOrigin, getStoreNode, getUserName } from "../helpers/Utility";

const pathMap = {
  packedOrders: {
    link: `/som-static/packedOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Packed Orders'
  },
  acknowledgeOrders: {
    link: `/som-static/acknowledgeOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Acknowledge Orders'
  },
  openOrders: {
    link: `/som-static/openOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Open Orders'
  },
  orderManifest: {
    link: `/som-static/orderManifest.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Manifest'
  },
  returnSearchOrder: {
    link: `/som-static/returnSearchOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Return search order'
  },
  returnAcceptedOrder: {
    link:`/som-static/returnAcceptedOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Return Accepted Order'
  },
  processedReturnOrder: {
    link: `/som-static/processedReturnOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Processed Return Order'
  },
  downloadInventory: {
    link: `/som-static/downloadInventory.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Download Inventory'
  },
  uploadInventory: {
    link: `/som-static/uploadInventory.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Upload Inventory'
  },
  dropstoreSearchOrder: {
    link: `/som-static/dropstoreSearchOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Dropstore Search Order'
  },
  searchOrder: {
    link: `/som-static/searchOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Search Order'
  },
  summary: {
    link: `/som-static/summary.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'summary'
  },
  forwardUploadGrnOrders: {
    link: `/som-static/forwardUploadGrnOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Forward Upload Orders'
  },
  forwardDownloadHoldOrders: {
    link: `/som-static/forwardDownloadHoldOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Forward Download HoldOrders'
  },
  storeManagerForwardSerach: {
    link: `/som-static/storeManagerForwardSerach.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'StoreManager Forward Order'
  },
  storeManagerReturnSerach: {
    link: `/som-static/storeManagerReturnSerach.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'StoreManager Return Search'
  },
  nodeCapacityUpload: {
    link: `/som-static/nodeCapacityUpload.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Node Capacity Upload'
  },
  nodeControlUpload: {
    link: `/som-static/nodeControlUpload.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Node control Upload'
  },
  nodeSafetyFactorUpload: {
    link: `/som-static/nodeSafetyFactorUpload.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`,
    pageName: 'Node safety factor'
  }
};

export default pathMap;
