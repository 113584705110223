import React from "react";
import { Switch, Route, BrowserRouter, HashRouter, Redirect } from "react-router-dom";
import AppDrawer from "./modules/PickFromStore/AppDrawer/AppDrawer";
import SOMAppDrawer from "./modules/common/SOMAppDrawer/SOMAppDrawer";
import RoutingUtility from "./helpers/RoutingUtility/RoutingUtility";
import NodeStatus from "./modules/Admin/NodeStatus/NodeStatus";
import Unauthorized from "./modules/common/Unauthorized";
import UnRegister from "./helpers/UnRegister";
import Login from "./modules/Auth/Login";
import DefaultLandingPage from "./modules/common/DefaultLandingPage";


// const ModuleRoutes = () => {
//   const storeNode = localStorage.getItem("store_node");
//   const userName = localStorage.getItem("user_name");
//   if (storeNode && userName && storeNode.length > 0 && userName.length > 0) {
//     return (
//       <HashRouter>
//         <>
//           <Switch>
//             <Route path="/login" component={Login} />
//             <Route path="/clearCache" component={UnRegister} />
//             <Route path="/nodeStatus" component={NodeStatus} />
//             <Route path="/som" component={SOMAppDrawer} />
//             <Route path="/trackOrder" component={AppDrawer} />
//             <Route path="/" component={RoutingUtility} />
//             <Route component={Unauthorized} />
//           </Switch>
//         </>
//       </HashRouter>
//     );
//   } else {
//     const originName = window.localStorage.getItem("origin");
//     window.location.replace(`/login.html?origin=MS1hamlv`);
//   }
// };

const ModuleRoutes = () => {
    return (
      <HashRouter>
        <>
          <Switch>
              <Route path="/login" component={Login} />
              <Route path="/clearCache" component={UnRegister} />
              <Route path="/nodeStatus" component={NodeStatus} />
              <Route path="/som" component={SOMAppDrawer} />
              <Route path="/trackOrder" component={AppDrawer} />
              <Route path='/som/home' component={DefaultLandingPage} />
              <Route path="/" component={RoutingUtility} />
              <Route component={Unauthorized} />
          </Switch>
        </>
      </HashRouter>
    );
};

export default ModuleRoutes;
