const dropAtStoreMenu = [
    {
        path: "/returnOrderSearch",
        menuName: "All Order",
        importPath: 'ProcessReturn/OrderSearch',
        component: 'OrderSearch',
        showOnMenu: false
    },
    {
        path: "/createReason",
        menuName: "All Order",
        importPath: 'ProcessReturn/CreateReason',
        component: 'CreateReason',
        showOnMenu: false
    },
    {
        path: "/saveQC",
        menuName: "All Order",
        importPath: 'ProcessReturn/SaveQC',
        component: 'SaveQC',
        showOnMenu: false
    },
    {
        path: "/refundSummary",
        menuName: "All Order",
        importPath: 'RefundSummary/RefundSummary',
        component: 'RefundSummary',
        showOnMenu: false
    },
    {
        path: "/shipmentListing",
        menuName: "All Order",
        importPath: 'ProcessReturn/ShipmentListing',
        component: 'ShipmentListing',
        showOnMenu: false
    }
]

export default dropAtStoreMenu;