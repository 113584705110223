import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import "./global.css";
import Button from "@material-ui/core/Button";
import ModuleRouters from "./ModuleRoutes";
import CustomerContextProvider from "./contexts/CustomerContext";

const theme = createMuiTheme({
  status: {
    danger: "orange",
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
  },
  palette: {
    primary: {
      main: "#2f4051",
    },
    secondary: {
      main: "#e30414", // '#B0BEC5'
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeType: false,
    };
  }

  render() {
    return (
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ width: "50%" }}
          action={<Button size="small">Dismiss</Button>}
        >
          <>
            <CssBaseline />
            <CustomerContextProvider>
              <ModuleRouters />
            </CustomerContextProvider>
          </>
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}
export default App;
