export const focMenu_Child = [
  {
    path: "/som/forcon",
    menuName: "Forward Consolidation",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "FOC",
    subMenu: [
      {
        path: "/som/forcon/forwardDownloadHoldOrders",
        menuName: "Download Hold Orders",
        importPath: "StoreOrder/ForwardDownloadHoldOrders",
        component: "IframeContainer",
        showOnMenu: true,
        default: false
      },
      {
        path: "/som/forcon/StoreOrders",
        menuName: "FOC Store Orders",
        importPath: "StoreOrder/FocOrderForStore",
        component: "StoreOrder",
        showOnMenu: true,
        default: false,
        icon: "StoreIcon"
      },
      {
        path: "/som/forcon/STO",
        menuName: "STO",
        importPath: "StoreOrder/StockTransferOrder",
        component: "StoreOrder",
        showOnMenu: true,
        default: false,
        icon: "StoreIcon"
      },
      {
        path: "/som/forcon/ProceedToShortPick",
        menuName: "ProceedToShortPick",
        importPath: "StoreOrder/ProceedToShortPick",
        component: "StoreOrder",
        showOnMenu: false,
        default: false,
        icon: "StoreIcon"
      }
    ]
  }
];
