export const focMenu_Master = [
  {
    path: "/som/forwCon/page/forwardDownloadHoldOrders",
    menuName: "Download Hold Orders",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "Download"
  },
  {
    path: "/som/forwCon1/page/forwardUploadGrnOrders",
    menuName: "Hold Resolve - Upload",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "Upload"
  }
];
