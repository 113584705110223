import React, { useEffect, useState, useContext, useRef } from "react";
import {
  successHandler,
  catchHandler,
  errorHandler,
  toGetShipNode,
  authToken,
  getStoreNode,
  setCookie,
  logout,
} from "../../helpers/Utility";
import http from "../../apis/http";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import { CustomerContext } from "../../contexts/CustomerContext";
import { KEYCLOAK } from "../../constants";
import config from "../../configs";

const useStyles = makeStyles((theme) => ({
  body: {
    background: "black",
  },
  paper: {
    display: "grid",
    padding: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      width: "75%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
    textAlign: "center",
  },
  textBox: {
    margin: theme.spacing(2),
  },
  textField: {
    textAlign: "none",
    width: "100%",
  },
  buttonField: {
    // marginTop: theme.spacing(1),
    width: "100%",
  },
  errorText: {
    textAlign: "left",
    padding: "0px 16px",
    color: "#f44336",
  },
  wrapper: {
    margin: theme.spacing(2),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Login(props) {
  const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorCredentials, setErrorCredentials] = useState(false);
  const { setName, setPassword, setUserList } = useContext(CustomerContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    logout();
  }, []);

  const onChange = (event, type) => {
    setErrorCredentials(false);
    setError(false);
    if (type === "name") {
      setUserName(event.target.value);
      setName(event.target.value);
    } else if (type === "password") {
      setUserPassword(event.target.value);
      setPassword(event.target.value);
    }
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      onSubmit();
    }
  };

  const fetchAllUsers = (token) => {
    const { enqueueSnackbar } = props;
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        realm: KEYCLOAK.realm,
      },
    };
    const url = `/loginservice/v2/users?node=${toGetShipNode()}`;
    http
      .get(url, headers)
      .then(
        (response) => {
          if (response && response.data.status === "SUCCESS") {
            setUserList(response.data.result);
          }
        },
        (err) => {
          errorHandler(enqueueSnackbar, err);
        }
      )
      .catch((err) => {
        catchHandler(enqueueSnackbar, err);
      });
  };

  const sterlingApi = () => {
    const { enqueueSnackbar } = props;
    let payload = {
      payload: {
        username: btoa(userName),
        password: btoa(userPassword),
      },
    };
    http
      .post("/dashboard/v1/login_api?origin=MS1hamlv", payload, {
        headers: {
          is_encrypted: true,
        },
      })
      .then((res) => {
        if (res.data.status_flag) {
          localStorage.setItem("seller_org_code", res.data.seller_org_code);
          localStorage.setItem("user_type", res.data.user_type);
          localStorage.setItem("user_name", res.data.user_name);
          localStorage.setItem("store_node", res.data.store_node);
          setCookie(
            res.data.store_node + "_JSESSIONID",
            res.headers["x-auth-token"].replace("]", "").replace("[", "")
          );
        } else {
          errorHandler(enqueueSnackbar, res?.data?.status_content);
          logout();
        }
      })
      .catch((err) => {
        errorHandler(enqueueSnackbar, err);
        logout();
      });
  };

  const onSubmit = () => {
    const { enqueueSnackbar } = props;
    setLoading(true);
    if (userName && userPassword) {
      const payload = {
        payload: {
          password: btoa(userPassword),
          username: btoa(userName.toLowerCase()),
          clientId: KEYCLOAK.clientID,
          clientSecret: KEYCLOAK.clientSecret,
          grantType: "password",
          doNotAllowMultipleLogins: false,
        },
      };
      const headers = {
        headers: {
          realm: KEYCLOAK.realm,
        },
      };

      http
        .post("/dashboard/v1/keyCloak_login", payload, headers)
        .then(
          (rsp) => {
            if (rsp.data.status === "FAIL") {
              setErrorCredentials(true);
              setLoading(false);
            } else if (rsp.data.status === "SUCCESS") {
              localStorage.setItem("access_token", rsp.data.result.accessToken);
              localStorage.setItem(
                "refresh_token",
                rsp.data.result.refreshToken
              );
              sterlingApi();
              fetchAllUsers(rsp.data.result.accessToken);
              props.history.push("/som/home");
              setLoading(false);
            }
          },
          (err) => {
            setErrorCredentials(true);
            setLoading(false);
            errorHandler(enqueueSnackbar, "Failed to login");
          }
        )
        .catch((err) => {
          setErrorCredentials(true);
          setLoading(false);
          catchHandler(enqueueSnackbar, err);
        });
    } else {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "#e6dada" /* fallback for old browsers */,
        background:
          "-webkit-linear-gradient(to right, #e6dada, #274046)" /* Chrome 10-25, Safari 5.1-6 */,
        background:
          "linear-gradient(to right, #e6dada, #274046)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
        position: "relative",
      }}
    >
      <Paper
        elevation={5}
        className={classes.paper}
        style={{
          borderRadius: 10,
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          top: "45%",
        }}
      >
        <img
          src="/ajio-logo.jpg"
          style={{
            margin: "16px auto",
            paddingBottom: 0,
            width: "100px",
          }}
        />
        <h3 style={{ color: "#4A6279", margin: 0 }}>Store Order Management</h3>
        <div className={classes.textBox}>
          <TextField
            className={classes.margin}
            id="input-with-icon-textfield"
            required
            label="User Name"
            value={userName}
            onChange={(e) => onChange(e, "name")}
            onKeyPress={handleKeypress}
            autoFocus
            variant="outlined"
            classes={{ root: classes.textField }}
            error={error}
            helperText={error ? "Please Enter Name" : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle style={{ color: "#1f4037" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.textBox}>
          <TextField
            className={classes.margin}
            id="input-with-icon-password"
            required
            type="password"
            variant="outlined"
            label="Password"
            value={userPassword}
            onChange={(e) => onChange(e, "password")}
            onKeyPress={handleKeypress}
            error={error}
            helperText={error ? "Please Enter password" : ""}
            classes={{ root: classes.textField }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon style={{ color: "#1f4037" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Typography
          className={classes.errorText}
          align="left"
          variant="caption"
        >
          {errorCredentials ? "Please Enter Valid credentials" : ""}
        </Typography>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonField}
            onClick={onSubmit}
            disabled={loading}
            style={{ background: loading ? "" : "#274046" }}
          >
            Login
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Paper>
    </div>
  );
}

export default withSnackbar(withRouter(Login));
