const storeAndDropAtStore = [
  {
    path: "/som/shipFromStore",
    menuName: "Ship From Store",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: true,
    icon: "ShipfromStore",
    subMenu: [
      // {
      //   path: "/som/shipFromStore/page/searchOrder",
      //   menuName: "Search Order",
      //   importPath: "IframeContainer/IframeContainer",
      //   component: "IframeContainer",
      //   showOnMenu: true,
      //   default: false
      // },
      // {
      //   path: "/som/shipFromStore/page/openOrders",
      //   menuName: "Open Orders",
      //   importPath: "IframeContainer/IframeContainer",
      //   component: "IframeContainer",
      //   showOnMenu: true
      // },
      // {
      //   path: "/som/shipFromStore/page/acknowledgeOrders",
      //   menuName: "Accepted orders",
      //   importPath: "IframeContainer/IframeContainer",
      //   component: "IframeContainer",
      //   showOnMenu: true
      // },
      // {
      //   path: "/som/shipFromStore/page/packedOrders",
      //   menuName: "Packed orders",
      //   importPath: "IframeContainer/IframeContainer",
      //   component: "IframeContainer",
      //   showOnMenu: true
      // },
      // {
      //   path: "/som/shipFromStore/page/orderManifest",
      //   menuName: "Close manifest",
      //   importPath: "IframeContainer/IframeContainer",
      //   component: "IframeContainer",
      //   showOnMenu: true
      // },
      // {
      //   path: "/som/incentives",
      //   menuName: "Fullfilment Incentive",
      //   importPath: "Incentives/Incentives",
      //   component: "Incentives",
      //   showOnMenu: true
      // }
      {
        path: "/som/shipFromStore/page/searchOrder",
        menuName: "Search Order",
        importPath: "ShipFromStore/Orders/Orders",
        component: "Orders",
        showOnMenu: true,
        default: false
      },
      {
        path: "/som/shipFromStore/page/pickListAssignment",
        menuName: "Pick-List Assignment",
        importPath: "ShipFromStore/PickListAssignment/pickListAssignment",
        component: "PickListAssignment",
        showOnMenu: true,
        default: false
      },
      {
        path: "/som/shipFromStore/page/pendingResolution",
        menuName: "Pending Resolution",
        importPath: "ShipFromStore/PendingResolution/pendingResolution",
        component: "PendingResolution",
        showOnMenu: true,
        default: false
      },
      {
        path: "/som/shipFromStore/page/confirmResolution",
        menuName: "Pending Resolution",
        importPath: "ShipFromStore/PendingResolution/confirmResolution",
        component: "ConfirmResolution",
        showOnMenu: false,
        default: false
      },
      {
        path: "/som/shipFromStore/page/confirmOrders",
        menuName: "Confirm Orders",
        importPath: "ShipFromStore/AcceptedOrders/confirmOrders",
        component: "AcceptedOrders",
        showOnMenu: true,
        default: false
      },
      {
        path: "/som/shipFromStore/page/manifestListing",
        menuName: "Manifest Listing",
        importPath: "ShipFromStore/Manifest/ManifestListing",
        component: "ManifestListing",
        showOnMenu: true,
        default: false
      },
      {
        path: "/som/shipFromStore/page/manifestDetails",
        menuName: "Manifest Details",
        importPath: "ShipFromStore/Manifest/ManifestDetails",
        component: "ManifestDetails",
        showOnMenu: false,
        default: false,
      }
    ]
  },
  {
    path: "/som/pickFromStoreVerification",
    menuName: "Customer orders",
    importPath: "PickFromStore/ProcessDelivery/PickfromStoreVerification",
    component: "PickFromStoreVerification",
    showOnMenu: true,
    icon: "CustomerOrders"
  },
  {
    path: "/som/returnOrder",
    menuName: "Return Orders",
    importPath: "ProcessReturn/ReturnOrdersHome",
    component: "ReturnOrders",
    showOnMenu: true,
    icon: "ReturnOrders"
  },
  {
    path: "/som/readyForInward",
    menuName: "Inward Order",
    importPath: "InwardOrder/ReadyForInward",
    component: "ReadyForInward",
    showOnMenu: true,
    icon: "InwardOrders"
  },
  {
    path: "/som/returnOrders",
    menuName: "Return orders",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: false,
    icon: "ReturnOrders",
    subMenu: [
      {
        path: "/som/returnOrders/page/returnSearchOrder",
        menuName: "Return Search Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: false
      },
      {
        path: "/som/returnOrders/page/returnAcceptedOrder",
        menuName: "Return Accepted Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: false
      },
      {
        path: "/som/returnOrders/page/processedReturnOrder",
        menuName: "Processed Return Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: false
      }
    ]
  },
  {
    path: "/som/createReturn",
    menuName: "Create return",
    importPath: "IframeContainer/IframeContainer",
    component: "IframeContainer",
    showOnMenu: false,
    icon: "ViewModuleIcon",
    subMenu: [
      {
        path: "/som/createReturn/page/dropstoreSearchOrder",
        menuName: "Search Order",
        importPath: "IframeContainer/IframeContainer",
        component: "IframeContainer",
        showOnMenu: true
      }
    ]
  },
  {
    path: "/som/callCustomer",
    menuName: "Call Customer",
    importPath: "CallCustomers/CallCustomers",
    component: "CallCustomers",
    showOnMenu: true,
    icon: "CallCustomer"
  }
];
export default storeAndDropAtStore;
