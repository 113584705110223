import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import http from "../../../apis/http";
import {
  catchHandler,
  errorHandler,
  successHandler,
  authToken,
} from "../../../helpers/Utility";
import moment from "moment";
import { useTable } from "react-table";
import { Tooltip } from "@material-ui/core";


const NodeEnableLabels = () => (
  <ul style={{ padding: 4 }}>
    <Typography variant='caption' style={{ fontWeight: 'bold' }} gutterBottom>I authorize that the following approvals have been taken:</Typography>
    <Typography variant='caption'><li style={{ marginLeft: "15px" }}>LM team has approved and configured the node in their system.</li></Typography>
    <Typography variant='caption'><li style={{ marginLeft: "15px" }}>POSDM team has approved and configured the node in their system.</li></Typography>
  </ul>
);

const DisableNodeReasons = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "Infra/Resource issues (Internet / Printer / Scanner / Manpower / Pack Material)",
    label: "Infra/Resource issues (Internet / Printer / Scanner / Manpower / Pack Material)"
  },
  {
    value: "PI Audit",
    label: "PI Audit",
  },
  {
    value: "Mall Entry / Timing restrictions",
    label: "Mall Entry / Timing restrictions",
  },
  {
    value: "Store Renovation",
    label: "Store Renovation",
  },
  {
    value: "Strategic Decision",
    label: "Strategic Decision",
  },
  {
    value: "COVID Lockdown",
    label: "COVID Lockdown",
  },
  {
    value: "Capacity breach",
    label: "Capacity breach",
  },
  {
    value: "Calamity / Natural Disaster in the region",
    label: "Calamity / Natural Disaster in the region",
  },
  {
    value: "Poor performance",
    label: "Poor performance",
  },
];

const NodeExtFlag = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "Y",
    label: "Consolidation",
  },
  {
    value: "N",
    label: "Fulfilment",
  },
];

const styles = makeStyles((theme) => ({
  root: {
    // marginTop: "4em"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textFieldReport: {
    marginLeft: theme.spacing * 4,
    marginRight: theme.spacing * 4,
    width: 250,
    display: "inline",
  },
  marginRight: {
    marginRight: "1em",
  },
  buttonSearch: {
    display: "inline-block",
    justifyContent: "flex-end",
    height: "100%",
    marginTop: "0.6em",
    marginRight: "1em",
  },
  buttonProgress: {
    right: "30%",
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "50%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  nodeName: {
    marginTop: "1em",
  },
  updateNodeBtn: {
    height: "50%"
  },
  warningMsg: {
    color: "#d32f2f",
  },
  nodeSelect: {
    width: 160,
  },
  buttonProgress1: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "55%",
    marginTop: -12,
    marginLeft: -12,
  },
  btnFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    marginTop: "100px",
  },
  serviceAble: {
    display: 'flex',
    margin: 10
  },
  classNumber: {
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0
    },
    '&::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0
    }
  },
  flexInfo: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const NodeStatus = (props) => {
  const [nodeId, setNodeId] = useState("");

  const [zipCode, setZipCode] = React.useState("");

  const [isServiceable, setIsServiceable] = React.useState(true);

  const [nodeName, setNodeName] = useState("");

  const [isActive, setIsActive] = useState("");

  const [nodeDisableReason, setNodeDisableReason] = useState("");

  let [selectedDate, setSelectedDate] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isUpdateInProcess, setIsUpdateInProcess] = useState(false);

  const [checkedNode, setCheckedNode] = useState(false);

  const [checkBoxConditions, setCheckBoxCondition] = useState({
    enableNodeCondition1: false,
    enableNodeCondition2: false
  });

  const [isEligible, setIsEligible] = useState("N");

  const [open, setOpen] = React.useState(false);

  const [nodeState, setNodesSelect] = useState({
    nodeActive: "",
    extnFulConsolidateNode: "",
  });

  const [nodeFields, setNodeFields] = useState({
    node_capacity: "",
    pob_buffer: "",
    node_tat: "",
    breach_multiple: ""
  });

  const [confirmVal, setConfirmVal] = useState({
    fieldName: "",
    fieldValue: "",
  });

  const [loading, setLoading] = React.useState(false);

  const [btnDisable, setBtnDisable] = React.useState(false);

  const [nodeFieldsDisable, setNodeFieldsDisable] = React.useState(false);

  const [nodeValue, setNodeValue] = React.useState("");

  const [nodeType, setNodeType] = React.useState("");

  const [nodeBusiness, setNodeBusiness] = React.useState("");

  const [nodeTypeForServ, setNodeTypeForServ] = React.useState("");

  const effectiveEndDate = "2025-12-01T00:00:00";

  const [openForDisableNode, setOpenForDisableNode] = useState(false);

  const { enqueueSnackbar } = props;

  const [verifySaved, setVerifySaved] = React.useState(false);
  const [isCapcityUploadVerified, setisCapcityUploadVerified] = React.useState(false);

  const classes = styles();

  const handleNodeIdChange = (event) => {
    setNodeId(event.target.value);
  };

  const handleClose = (name) => {
    if (name !== "") {
      setNodeFields({ ...nodeFields, [name]: "" });
    }
    setOpen(false);
  };

  //Redirect to Admin Legacy Som Panel
  const redirectToLegacySom = () => {
    window.location.href = `/storeManagerForwardSerach.html?store_node=${localStorage.getItem(
      "store_node"
    )}&user_name=${localStorage.getItem("user_name")}&origin=MS1hamlv`;
  };

  // Node Enable/Disable func
  const handleChangeIsActive = (event) => {
    if (event.target.checked) {
      setCheckedNode(true);
      setSelectedDate(new Date());
    } else {
      setCheckedNode(false);
    }
  };

  const isConditionChecked = (event, type) => {
    if (event.target.checked) {
      setCheckBoxCondition({ ...checkBoxConditions, [type]: true })
    } else {
      setCheckBoxCondition({ ...checkBoxConditions, [type]: false })
    }
  }

  // const handleNodeType = (event) => {
  //   setNodeType(event.target.value);
  // };

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    if (name === "nodeDisableReason") {
      setNodeDisableReason(value);
    } else {
      setNodesSelect({ ...nodeState, [name]: value });
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function NodeBusinessConditions(business) {
    if (business && business.includes("TRENDS")) {
      setNodeType("Y")
    } else if (business && business.includes("FOOTPRINT")) {
      setNodeType("Y")
    } else {
      setNodeType("optional")
      // 273750 - Verify button not required for node business type Others(null,"",VENDOR etc.,)
      setisCapcityUploadVerified(true);
    }

  }

  // Reset Values
  const resetValues = () => {
    setNodeId("");
    setNodeName("");
    setNodeType("");
    setNodeTypeForServ("");
    setNodeBusiness("");
    setNodeValue("");
    setIsActive("");
    setOpenForDisableNode(false);
    setZipCode("");
    setIsServiceable(true);
    //setNodeStatus(true);
    setSelectedDate("");
    setNodeDisableReason("");
    setIsLoading(false);
    setIsUpdateInProcess(false);
    setIsEligible("N");
    setCheckedNode(false);
    setCheckBoxCondition({
      enableNodeCondition1: false,
      enableNodeCondition2: false
    })
    //setCheckTnc(false);
    setNodeFields({
      node_capacity: "",
      pob_buffer: "",
      node_tat: "",
      breach_multiple: "",
    });
    setNodesSelect({ nodeActive: "", extnFulConsolidateNode: "" });
    setVerifySaved(false);
    setisCapcityUploadVerified(false);
  };

  const checkServiceability = () => {
    const headers = {
      headers: {
        "x-auth-token": authToken(),
      },
    };
    const reqData = {
      zipcode: zipCode,
      node: nodeValue,
      node_type: nodeTypeForServ
    };
    http.post(`/dashboard/v1/node/zipcode/serviceabilityCheck`, reqData, headers)
      .then(
        (rsp) => {
          if (rsp.data.serviceability) {
            successHandler(enqueueSnackbar, `${rsp.data.message}`);
            NodeBusinessConditions(nodeBusiness);
            setIsServiceable(false);
          } else {
            errorHandler(enqueueSnackbar, `${rsp.data.message}`);
            setIsServiceable(true);
            // resetValues();
          }
        },
        (err) => {
          errorHandler(enqueueSnackbar, err);
          setIsServiceable(true);
        }
      )
      .catch((rsp) => {
        catchHandler(enqueueSnackbar, rsp);
        setIsServiceable(true);
      });
  }

  const closeDialogForDisableNode = () => {
    setOpenForDisableNode(false);
  }

  const searchNodes = () => {
    setNodeValue(nodeId);
    setIsLoading(true);
    setIsUpdateInProcess(false);
    setCheckedNode(false);
    const headers = {
      headers: {
        "x-auth-token": authToken(),
      },
    };
    http
      .get(`/dashboard/v1/get_node?node_id=${nodeId}`, headers)
      .then(
        (rsp) => {
          if (rsp.data.status) {
            if (!!rsp.data.node_id) {
              resetValues();
              setNodeValue(nodeId);
              setNodeFieldsDisable(false);
              setBtnDisable(false);
              setNodeId(rsp.data.node_id);
              setNodeName(rsp.data.node_name);
              setIsActive(rsp.data.is_active);
              setNodeTypeForServ(rsp.data.node_type);
              setNodeBusiness(rsp.data.node_business && rsp.data.node_business.toUpperCase());
              //setNodeStatus(rsp.data.status);
              setZipCode(rsp.data.zipcode);
              setIsLoading(false);
              setIsUpdateInProcess(false);
              //setCheckTnc(false);
              setSelectedDate(new Date());
              setIsEligible(rsp.data.is_eligible);
              localStorage.setItem("node_type", rsp && rsp.data && rsp.data.node_type || "");
            } else {
              errorHandler(
                enqueueSnackbar,
                `Node ${nodeId} cannot be Enabled /Disabled using this functionality`
              );
              resetValues();
            }

            if (rsp.data.is_active === "N") {
              setSelectedDate(new Date());
            }
          } else {
            errorHandler(enqueueSnackbar, `Node ${nodeId} not found.`);
            resetValues();
          }
        },
        (err) => {
          errorHandler(enqueueSnackbar, err);
          resetValues();
        }
      )
      .catch((rsp) => {
        catchHandler(enqueueSnackbar, rsp);
        resetValues();
      });
  };

  const handleConfimation = (e, name) => {
    e.preventDefault();
    if (e.target.value !== "") {
      if (name === "node_capacity") {
        if (Number(nodeFields[name]) > 100) {
          setConfirmVal({ fieldName: name, fieldValue: nodeFields[name] });
          setOpen(true);
        } else {
          setOpen(false);
        }
      } else if (name === "node_tat") {
        if (Number(nodeFields[name]) < 24 || Number(nodeFields[name]) > 120) {
          setConfirmVal({ fieldName: name, fieldValue: nodeFields[name] });
          setOpen(true);
        } else {
          setOpen(false);
        }
      } else {
        if (Number(nodeFields[name]) > 5) {
          setConfirmVal({ fieldName: name, fieldValue: nodeFields[name] });
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    }
  };

  const handleNodeFields = (event, name) => {
    const { value } = event.target;
    let regex = /^[0-9\b]+$/;
    if (event.target.value === "" || (event.target.value !== "0" && regex.test(event.target.value))) {
      setNodeFields({ ...nodeFields, [name]: value });
    }
  };

  const dialogToDisableNode = () => {
    setOpenForDisableNode(true);
  }

  const toGetNames = (name) => {
    if (name === "node_capacity") {
      return "Node Capacity";
    } else if (name === "node_tat") {
      return "Node TAT";
    } else {
      return "Breach Multiple";
    }
  };

  const updateNode = () => {
    setIsLoading(false);
    setIsUpdateInProcess(true);

    const headers = {
      headers: {
        "x-auth-token": authToken(),
      },
    };

    if (typeof selectedDate === "object") {
      selectedDate = moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss");
    }

    let reqData = {}

    if (isActive === "Y") {
      reqData = {
        node_id: nodeValue.trim(),
        is_active: isActive === "Y" ? "N" : "Y",
        effective_date: isActive === "Y" ? effectiveEndDate : selectedDate,
        reason: nodeDisableReason.trim(),
        node_type:localStorage.getItem('node_type') || ""
      };
    } else {
      reqData = {
        node_id: nodeValue.trim(),
        is_active: isActive === "Y" ? "N" : "Y",
        effective_date: isActive === "Y" ? effectiveEndDate : selectedDate,
        node_type:localStorage.getItem('node_type') || ""
      };
    }

    http
      .post(`/dashboard/v1/post_api?option=update_node`, reqData, headers)
      .then(
        (rsp) => {
          if (rsp.data.status.toLowerCase() === "failure") {
            errorHandler(enqueueSnackbar, rsp.data.message);
            setIsUpdateInProcess(false);
            setOpenForDisableNode(false);
          } else if (rsp.data.status.toLowerCase() === "success") {
            const effective_date = JSON.parse(rsp.config.data).effective_date;
            successHandler(
              enqueueSnackbar,
              `Node will be 
            ${isActive === "Y" ? "disabled till " : "enabled from "}
            ${isActive === "Y" ? effectiveEndDate : selectedDate}`
            );
            setIsLoading(false);
            setIsUpdateInProcess(false);
            setOpenForDisableNode(false);
          }
        },
        (err) => {
          errorHandler(enqueueSnackbar, err);
          resetValues();
        }
      )
      .catch((rsp) => {
        catchHandler(enqueueSnackbar, rsp);
        resetValues();
      });
  };

  const saveInfo = (q) => {
    setLoading(true);
    const headers = {
      headers: {
        "x-auth-token": authToken(),
      },
    };
    const postURL = (q=="callVerify")? `/dashboard/v1/post_api?option=validateCapacity`:`/dashboard/v1/save/node_capacity`;

    const reqData = {
      extnNodeControlFlag: "",
      extnNodeCapacityConsumedFlag: "",
      extnShipNode: nodeValue.trim(),
      extnNodeCapacity: nodeFields["node_capacity"].trim(),
      extnNodeCapacityConsumption: "",
      extnBreachMultiple: nodeFields["breach_multiple"].trim(),
      extnPOBBuffer: nodeFields["pob_buffer"].trim(),
      extnNodeTat: nodeFields["node_tat"].trim(),
      extnActiveFlag: nodeState["nodeActive"] ? "Y" : "N",
      extnFulConsoldateNode: nodeState["extnFulConsolidateNode"].trim(),
      node_type:localStorage.getItem('node_type') || ""
    };

    http
      .post(postURL, reqData, headers)
      .then(
        (rsp) => {
          if (rsp.data && rsp.data.status || rsp.data.success) {
            setNodeFieldsDisable(true);
            setBtnDisable(true);
            setLoading(false);
            setCheckedNode(true)
            if(q=="callVerify" && rsp.data && rsp.data.success){
              setisCapcityUploadVerified(true);
            }
            successHandler(enqueueSnackbar, rsp.data.error_message || rsp.data.result);
            setVerifySaved(true);
          } else {
            setLoading(false);
            setVerifySaved(q=="callVerify"?true:false);
            setisCapcityUploadVerified(false);
            errorHandler(enqueueSnackbar, rsp.data.error_message || rsp.data.result);
          }
        },
        (err) => {
          setLoading(false);
          setVerifySaved(false);
          setCheckedNode(false);
          errorHandler(enqueueSnackbar, err);
          setisCapcityUploadVerified(false);
        }
      )
      .catch((rsp) => {
        setLoading(false);
        setVerifySaved(false);
        setCheckedNode(false);
        catchHandler(enqueueSnackbar, rsp);
        setisCapcityUploadVerified(false);
      });
  };

  // Static ExpansionPanelSummary
  const expansionPanelSummary = () => {
    return (
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <div className={classes.column}>
          <Typography component="h6" variant="h6">
            Search Nodes
          </Typography>
        </div>
      </ExpansionPanelSummary>
    );
  };

  // Static ExpansionDetails
  const expansionPanelDetails = () => {
    return (
      <ExpansionPanelDetails className={classes.details}>
        <div className={classes.column}>
          <FormControl>
            <InputLabel htmlFor="seller-id">Search Nodes</InputLabel>
            <Input
              required
              id="SearchStyle"
              label="Style ids comma separated"
              className={(classes.textFieldReport, classes.marginRight)}
              value={nodeId}
              onChange={handleNodeIdChange}
              inputProps={{ autoComplete: "off" }}
            // onBlur={validateFieldCallback({
            //   selectedReport: this.selectedReport
            // })}
            />
            {/* <FormHelperText>{helperText}</FormHelperText> */}
          </FormControl>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonSearch}
            onClick={searchNodes}
          >
            {!isLoading && <span>Search</span>}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonSearch}
            onClick={resetValues}
            disabled={isLoading}
          >
            <span>Clear All</span>
          </Button>
        </div>
      </ExpansionPanelDetails>
    );
  };

  // Static ExpansionPanelActions
  const expansionPanelActions = () => {
    return (
      <ExpansionPanelActions style={{ justifyContent: "inherit", padding: 20 }}>
        <div style={{ width: "85%" }}>
          {isActive === "N" && (
            <>
              <div style={{ marginBottom: 50, marginLeft: 20 }}>
                <div className={classes.flexInfo}>
                  <Typography variant='overline'>Node Business: <b>{nodeBusiness ? nodeBusiness : "others"}</b></Typography>
                </div>
                <br></br>
              </div>
              {(nodeType === "Y" || nodeType === "optional") && (
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={2} style={{ margin: "auto" }}>
                    <TextField
                      required={nodeType === "Y" ? true : false}
                      id="standard-read-only-input"
                      label="Node Capacity"
                      variant='outlined'
                      disabled={nodeFieldsDisable}
                      onChange={(e) => handleNodeFields(e, "node_capacity")}
                      onBlur={(e) => handleConfimation(e, "node_capacity")}
                      value={nodeFields.node_capacity}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ autoComplete: "off" }}
                      helperText=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ margin: "auto" }}>
                    <TextField
                      shrink
                      id="standard-read-only-input"
                      label="POB Buffer"
                      variant='outlined'
                      disabled={nodeFieldsDisable}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ marginLeft: "-10px" }}
                          >
                            hrs
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => handleNodeFields(e, "pob_buffer")}
                      value={nodeFields.pob_buffer}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ autoComplete: "off" }}
                      helperText=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ margin: "auto" }}>
                    <TextField
                      required={nodeType === "Y" ? true : false}
                      id="standard-read-only-input"
                      label="Node TAT"
                      disabled={nodeFieldsDisable}
                      variant='outlined'
                      autoComplete="false"
                      onBlur={(e) => handleConfimation(e, "node_tat")}
                      onChange={(e) => handleNodeFields(e, "node_tat")}
                      value={nodeFields.node_tat}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ marginLeft: "-10px" }}
                          >
                            hrs
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ autoComplete: "off" }}
                      helperText=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ margin: "auto" }}>
                    <TextField
                      required={nodeType === "Y" ? true : false}
                      id="standard-read-only-input"
                      label="Node Breach Multiple"
                      variant='outlined'
                      disabled={nodeFieldsDisable}
                      onBlur={(e) => handleConfimation(e, "breach_multiple")}
                      onChange={(e) => handleNodeFields(e, "breach_multiple")}
                      value={nodeFields.breach_multiple}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ autoComplete: "off" }}
                      helperText=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ margin: "auto" }}>
                    <TextField
                      required={nodeType === "Y" ? true : false}
                      id="standard-select-currency-native"
                      select
                      disabled={nodeFieldsDisable}
                      variant='outlined'
                      className={classes.nodeSelect}
                      label="Capacity Type"
                      value={nodeState.extnFulConsolidateNode}
                      onChange={handleChange("extnFulConsolidateNode")}
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    >
                      {NodeExtFlag.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {isActive === "N" && nodeType === "" && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.nodeName}
                  align="center"
                >
                  Node : {nodeName}
                </Typography>
                <Divider orientation="vertical" flexItem style={{ marginTop: 20 }} />
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.nodeName}
                  align="center"
                >
                  ZIP Code : {zipCode}
                </Typography>
              </div>
              {isEligible === "N" && (
                <Typography
                  component="body"
                  variant="body"
                  className={classes.warningMsg}
                  align="center"
                >
                  {`Node ${nodeId} cannot be Enabled / Disabled using this functionality`}
                </Typography>
              )}
            </>
          )}

          {(isActive === "Y" || nodeType === "optional" || (isActive === "N" && nodeType !== "")) && (
            <Grid container spacing={24}>
              <Grid item xs={12} sm={2} style={{ margin: "auto" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.nodeName}
                  align="center"
                >
                  Node : {nodeName}
                </Typography>
                {isActive === "N" && <Typography
                  component="h6"
                  variant="h6"
                  className={classes.nodeName}
                  align="center"
                >
                  ZIP Code : {zipCode}
                </Typography>}
                {isEligible === "N" && (
                  <Typography
                    component="body"
                    variant="body"
                    className={classes.warningMsg}
                    align="center"
                  >
                    {`Node ${nodeId} cannot be Enabled / Disabled using this functionality`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={2} style={{ margin: "auto" }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Status</FormLabel>
                  {isActive === "Y" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedNode}
                          onChange={(e) => handleChangeIsActive(e)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      }
                      label="Disable Node"
                    />
                  )}
                  {isActive === "N" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedNode}
                          onChange={(e) => handleChangeIsActive(e)}
                          disabled={nodeType === "Y" ? true : false}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      }
                      label="Enable Node"
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} style={{ margin: "auto" }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  {isActive === "N" && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <FormLabel component="legend">
                        Effective Start Date and Time
                      </FormLabel>
                      <DateTimePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                      <FormHelperText>
                        {`Node will be enabled from the effective date`}
                      </FormHelperText>
                    </MuiPickersUtilsProvider>
                  )}
                  {isActive === "Y" && (
                    <>
                      <FormLabel component="legend">
                        Effective End Date
                      </FormLabel>
                      <form className={classes.container} noValidate>
                        <TextField
                          id="selectedDate"
                          disabled
                          defaultValue="01 December 2025"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value="01 December 2025"
                        />
                      </form>
                      <FormHelperText>{`Node will be disabled till 01 December 2025`}</FormHelperText>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} style={{ margin: isActive === "N" ? "50px auto 0 auto" : "auto" }}>
                {isActive === "N" && (<><FormControlLabel
                  control={
                    <Checkbox
                      required
                      style={{ marginBottom: "15px" }}
                      checked={checkBoxConditions['enableNodeCondition1']}
                      onChange={(e) => isConditionChecked(e, "enableNodeCondition1")}
                      color="primary"
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                    />
                  }
                  label={<Typography variant='caption'>I authorize that node has been configured in SAP, Hybris and CAE</Typography>}
                />
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        style={{ marginBottom: "70px" }}
                        checked={checkBoxConditions['enableNodeCondition2']}
                        onChange={(e) => isConditionChecked(e, "enableNodeCondition2")}
                        color="primary"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label={NodeEnableLabels()}
                  /></>)}
                {isActive === "Y" && (<TextField
                  required
                  id="standard-select-currency"
                  select
                  label="Disable Reason"
                  style={{ width: 250, margin: 'auto' }}
                  value={nodeDisableReason}
                  onChange={handleChange("nodeDisableReason")}
                  helperText={!nodeDisableReason ? "Please select reason" : ""}
                >
                  {DisableNodeReasons.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>)}
              </Grid>
            </Grid>
          )}
        </div>
        <div className={isActive === "N" ? classes.btnFlex : ""}>
          {isActive === "N" && (nodeType === "Y" || nodeType === "optional") && (
            <div style={{ position: 'absolute', top: '52%', right: '1%'}}>
              {" "}
              <Button
                variant="contained"
                color="primary"
                size="small"
                //style={{ marginBottom: "50px" }}
                disabled={
                  nodeState["extnFulConsolidateNode"] === "" ||
                  nodeFields["node_capacity"] === "" ||
                  nodeFields["node_tat"] === "" ||
                  nodeFields["breach_multiple"] === "" ||
                  loading ||
                  btnDisable
                }
                onClick={() => saveInfo()}
                size="small"
              >
                save
              </Button>
              <Tooltip title="Please verify capcity updated successfuly to enable/disable node">
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: "10px" }}
                disabled={
                  !verifySaved
                }
                onClick={() => saveInfo("callVerify")}
                size="small"
              >
                verify
              </Button>
              </Tooltip>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress1}
                />
              )}
            </div>
          )}
          {(isActive === "Y" || (isActive === "N" && nodeType !== "" && isCapcityUploadVerified)) && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.updateNodeBtn}
              onClick={isActive === "N" ? updateNode : dialogToDisableNode}
              disabled={
                isActive === "N"
                  ? isUpdateInProcess || checkedNode === false || checkBoxConditions.enableNodeCondition1 === false || checkBoxConditions.enableNodeCondition2 === false
                  : isUpdateInProcess || checkedNode === false || nodeDisableReason === ""
              }
            >
              {!isUpdateInProcess && <span>Confirm</span>}
              {isUpdateInProcess && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          )}
        </div>
      </ExpansionPanelActions >
    );
  };

  return (
    <>
      {/* {appBar()} */}
      <br />
      <div className={classes.root}>
        <ExpansionPanel defaultExpanded>
          {expansionPanelSummary()}
          {expansionPanelDetails()}
          {!!nodeName && (
            <>
              <Divider />
              {isActive === "N" &&
                (
                  <div className={classes.serviceAble}><Typography
                    variant="h6"
                    style={{ margin: "8px 20px" }}
                    gutterBottom
                  >
                    Enter details for Node: <b>{nodeValue}</b>
                  </Typography>
                    <Button variant='outlined' color='primary' disabled={!isServiceable} onClick={checkServiceability} style={{ margin: 5 }}>check serviceability</Button></div>)}
              {expansionPanelActions()}
            </>
          )}
        </ExpansionPanel>
        <Dialog
          open={open}
          disableBackdropClick={true}
          onClose={handleClose}
          maxWidth={"sm"}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Please Confirm the Action"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure {toGetNames(confirmVal.fieldName)} is{" "}
              {confirmVal.fieldValue}{" "}
              {confirmVal.fieldName === "node_tat" ? "hr" : ""} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => handleClose(confirmVal.fieldName)}
              color="primary"
            >
              Disagree
            </Button>
            <Button onClick={(e) => handleClose("")} color="primary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openForDisableNode}
          disableBackdropClick={true}
          onClose={closeDialogForDisableNode}
          maxWidth={"sm"}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'center' }}>
            <Typography variant='h6'>Please Confirm the Action</Typography>
          </DialogTitle>
          <DialogContent style={{ textAlign: 'left' }}>
            <DialogContentText>
              <Typography variant='subtitle2' style={{ color: 'black' }}>On node disable, node capacity will become 0. Existing orders will be fulfilled and no fresh orders will come to the node.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => closeDialogForDisableNode(confirmVal.fieldName)}
              color='secondary'
              variant='text'
            >
              Cancel
            </Button>
            <Button onClick={updateNode} color="primary" variant='outlined'>
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default withSnackbar(NodeStatus);