import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import StoreIcon from "@material-ui/icons/Store";
import ShipfromStore from "@material-ui/icons/LocalShipping";
import CustomerOrders from "@material-ui/icons/Face";
import ReturnOrders from "@material-ui/icons/Undo";
import CallCustomer from "@material-ui/icons/Call";
import Logout from "@material-ui/icons/PowerSettingsNew";
import InwardOrders from "@material-ui/icons/Store";
import NodeConfig from "@material-ui/icons/Timeline";
import FOC from "@material-ui/icons/Sort";
import HoldOrders from "@material-ui/icons/PauseCircleFilled";
import STOCreation from "@material-ui/icons/Description";
import StoreSuperVision from "@material-ui/icons/Assignment";
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import AddIcon from '@material-ui/icons/Add';
import BarChartIcon from '@material-ui/icons/BarChart';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import SecurityIcon from '@material-ui/icons/Security';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import Upload from "@material-ui/icons/Backup";
import Download from "@material-ui/icons/CloudDownload";
import BuildIcon from '@material-ui/icons/Build';
import SyncAltRoundedIcon from '@material-ui/icons/SyncAltRounded';
import ReorderIcon from '@material-ui/icons/Reorder';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import LinkIcon from '@material-ui/icons/Link';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import HelpIcon from '@material-ui/icons/Help';

const icons = {
  ExitToAppIcon,
  ViewModuleIcon,
  CloudDownloadIcon,
  StoreIcon,
  ShipfromStore,
  CustomerOrders,
  ReturnOrders,
  CallCustomer,
  InwardOrders,
  Logout,
  NodeConfig,
  FOC,
  HoldOrders,
  STOCreation,
  StoreSuperVision,
  Upload,
  Download,
  YoutubeSearchedForIcon,
  AddIcon,
  ToggleOnIcon,
  BarChartIcon,
  VideogameAssetIcon,
  SecurityIcon,
  SecurityOutlinedIcon,
  VpnLockIcon,
  BuildIcon,
  SyncAltRoundedIcon,
  ReorderIcon,
  PlaylistAddIcon,
  LinkIcon,
  LowPriorityIcon,
  HelpIcon
};

const getIcon = stringIcon => {
  const icon = icons[stringIcon];
  return stringIcon ? React.createElement(icon, {}) : "";
};

export default getIcon;
