import React, { useEffect } from 'react';
import * as serviceWorker from "../serviceWorker";
import {
    getStoreOrigin,
    logout
  } from "./Utility";

function UnRegister(props) {
    let userOrigin = getStoreOrigin();

    useEffect(() => {
        serviceWorker.unregister();
        logout();
        // window.location.href = `/login.html?origin=${userOrigin}`;
        window.location = '/login';
    }, []);
    
    return (
        <>
        </>
    )
}

export default UnRegister;