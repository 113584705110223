import http from "../apis/http";
import * as FileDownload from "js-file-download";
import moment from "moment";

// export const dateTimeFormat = (date, format) => moment.utc(date).local().format('lll');

export const dateTimeFormat = (date, format) => moment(date).format("lll");

export const dateTimeFormatUTC = (date, format) =>
  moment
    .utc(date)
    .local()
    .format(format);

/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */

export const BackOption = (props, page) => {
  if (page === "/returnOrder") {
    window.location.hash = page;
  } else if (page === "/jioPoint.html/") {
    window.location.href = `/jioPoint.html?store_node=${localStorage.getItem(
      "store_node"
    )}&user_name=${localStorage.getItem("user_name")}&origin=MS1hamlv`;
  } else {
    props.history.push({
      pathname: page,
    });
  }
};

export const toGetIp = (url) => {
  let Finalurl = url.split("index")[0];
  return Finalurl;
};

export const getStoreOrigin = () => {
  return "MS1hamlv";
};

export const getStoreOriginForNodeControl = () => {
  const storeNode = localStorage.getItem("origin");
  let origin;
  if (storeNode === "Mi10cmVuZHM=") {
    origin = "Trends";
  } else {
    origin = "RIL";
  }
  return origin;
};

export const getStoreNode = () => {
  let user = decodeToken();
  if (user && user.shipnode) {
    return user.shipnode;
  }
  return "";
};

export const getUserName = () => {
  let user = decodeToken();
  if (user && user.name) {
    return user.name;
  }
  return "";
};

export const getStoreType = () => {
  let user = decodeToken();
  if (user && user.storetype) {
    return user.storetype;
  }
  return "";
};

export const getUsertype = () => {
  const userType = localStorage.getItem("user_type");
  return userType;
};

export const authToken = () => {
  const storeNode = localStorage.getItem("store_node");
  let cookieValue = storeNode + "_JSESSIONID";
  let value = "; " + document.cookie;
  let parts = value.split("; " + cookieValue + "=");
  let finalVal;
  if (parts.length == 2) {
    finalVal = parts
      .pop()
      .split(";")
      .shift();
  }
  return finalVal;
};

export const successHandler = (enqueueSnackbar, msg) => {
  let autoHideDuration = 3000;
  enqueueSnackbar(msg, {
    variant: "success",
    autoHideDuration,
  });
};

export const infoHandler = (enqueueSnackbar, msg) =>
  enqueueSnackbar(msg, {
    variant: "info",
  });

export const warningHandler = (enqueueSnackbar, msg) =>
  enqueueSnackbar(msg, {
    variant: "warning",
  });

// export const statusCodeErrorHandler = (enqueueSnackbar, rsp) => {
//   let errorMsg;
//   let autoHideDuration = 3000;
//   if (rsp.status_code === 500) {
//     errorMsg = rsp.error_message;
//   } else if (rsp.status_code === 401) {
//     let userOrigin = getStoreOrigin();
//     logout();
//     window.location.href = `/login.html?origin=${userOrigin}`;
//   } else if (rsp.status_code === 200) {
//     errorMsg = rsp.error_message;
//   } else {
//     errorMsg = "error Occurred";
//   }
//   enqueueSnackbar(errorMsg, {
//     variant: "error",
//     autoHideDuration
//   });
// };

export const errorHandler = (enqueueSnackbar, err) => {
  let errorMsg;
  let autoHideDuration = 3000;
  if (err.message === "Unauthorized access") {
    window.location.replace("/access-denied.html?origin=MS1hamlv");
  }
  if (typeof err === "string") {
    errorMsg = err;
  } else if (err.message === "Unauthorized access") {
    window.location.replace("/access-denied.html?origin=MS1hamlv");
  } else if (err.response) {
    errorMsg = err.response
      ? `${err.response.data.message ||
          (err.response.data &&
            err.response.data.errors &&
            err.response.data.errors[0].errorDescription) ||
          err.response.data.errorMessage ||
          err.response.data.errors.join(", ")}`
      : "Network Error";
  } else if (err.message && err.message.res) {
    errorMsg = err.message.res.message;
    autoHideDuration = 5000;
  } else if (err.status_code === 200) {
    errorMsg = err.error_message;
  } else if (err.status_code === 500) {
    errorMsg = err.error_message;
  } else {
    errorMsg =
      err.message !== undefined
        ? err.message
        : err.error_message !== undefined
        ? err.error_message
        : "Network Error";
  }

  enqueueSnackbar(errorMsg, {
    variant: "error",
    autoHideDuration,
  });
};

export const catchHandler = (enqueueSnackbar, err) => {
  console.log(err);
  let autoHideDuration = 3000;
  const errorMsg =
    err || "An error occured while perfoming this action. Please try again.";
  return enqueueSnackbar(errorMsg, {
    variant: "error",
    autoHideDuration,
  });
};

export const setTitle = (page) => {
  let title;
  switch (page) {
    case "Drop at store":
      document.title = "Drop at store";
      break;
    case "pick from store":
      document.title = "Pick from store";
      break;
    default:
      document.title = "Jio Point";
  }
  return title;
};

export const getRefundPaymentTypes = (type) => {
  let paymentType;
  switch (type) {
    case "Sterling Payment Method":
      paymentType = "SOM UI Display payment method";
      break;
    case "CASH":
      paymentType = "Cash Refund/adjustment";
      break;
    case "RAZORPAY":
      paymentType = "Online Transfer";
      break;
    case "PAYTMGATEWAY":
      paymentType = "Online Transfer";
      break;
    case "BILL_DESK":
      paymentType = "Online Transfer";
      break;
    case "RETURN_CREDIT":
      paymentType = "Ajio Cash Refund";
      break;
    case "AJIO_CASH":
      paymentType = "Ajio point Refund ";
      break;
    case "COD":
      paymentType = "Cash to be Collected";
      break;
    default:
    // code block
  }
  return paymentType;
};

export const getForwardPaymentTypes = (type) => {
  let paymentType;
  switch (type) {
    case "Sterling Payment Method":
      paymentType = "SOM UI Display payment method";
      break;
    case "CASH":
      paymentType = "Cash Refund/adjustment";
      break;
    case "RAZORPAY":
      paymentType = "Online Transfer";
      break;
    case "PAYTMGATEWAY":
      paymentType = "Online Transfer";
      break;
    case "BILL_DESK":
      paymentType = "Online Transfer";
      break;
    case "RETURN_CREDIT":
      paymentType = "Paid by Ajio Cash";
      break;
    case "AJIO_CASH":
      paymentType = "Paid by Ajio Points";
      break;
    case "COD":
      paymentType = "Cash to be Collected";
      break;
    default:
    // code block
  }
  return paymentType;
};

export const r = (enqueueSnackbar, err) => {
  console.log(err);
  const errorMsg =
    err || "An error occured while perfoming this action. Please try again.";
  return enqueueSnackbar(errorMsg, {
    variant: "error",
  });
};

export const downloadFile = (
  shipments,
  returnOrderList,
  shipment_no_list,
  enqueueSnackbar,
  callback
) => {
  let url;
  let obj = {
    shipments: shipments,
  };

  let orderNo = shipments && shipments[0].return_order_no;
  url = `/dashboard/v1/reciept/pdf?store_node=${getStoreNode()}&pdf_name=${
    orderNo[0]
  }`;

  const headers = {
    responseType: "arraybuffer",
    headers: {
      "x-auth-token": authToken(),
    },
  };

  http
    .post(url, obj, headers)
    .then(
      (response) => {
        FileDownload(
          response.data,
          response.headers["content-disposition"].split("=")[1]
        );
        callback();
      },
      (err) => errorHandler(enqueueSnackbar, err)
    )
    .catch((err) => catchHandler(enqueueSnackbar, err));
};

function deleteAllCookies() {
  document.cookie.split(";").forEach(function(c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

export function sortBy(arrayObj) {
  return arrayObj.sort((a, b) => (a.order_no > b.order_no ? 1 : -1));
}
export function groupBy(array, grpBy1, grpBy2) {
  let temp = array.sort(function(first, second) {
    if (
      moment(first[grpBy1]).format("DD-MM-YYYY") >
      moment(second[grpBy1]).format("DD-MM-YYYY")
    )
      return -1;
    if (
      moment(first[grpBy1]).format("DD-MM-YYYY") <
      moment(second[grpBy1]).format("DD-MM-YYYY")
    )
      return 1;
    if (first[grpBy2].toLowerCase() > second[grpBy2].toLowerCase()) return -1;
    if (first[grpBy2].toLowerCase() < second[grpBy2].toLowerCase()) return 1;
  });

  return temp;
}

export const listOfCancellationSubReasons = {
  itemAvailableReasons: [
    "Defective",
    "Missing Component",
    "No Tags",
    "Size Unavailable",
    "Article number mismatch",
    "Product Image different",
    "Product Description different",
    "EAN mismatch",
    "Color mismatch",
    "MRP mismatch",
  ],
  itemUnavailableReasons: [
    "No physical inventory",
    "Packed for Stock Transfer(IST)",
    "Packed for Stock Transfer(GRDC)",
    "Packed for RTV",
    "Sold on other online platform",
    "Sold offline",
    "Customer Booked",
    "No inventory on SAP System",
    "PI Audit Adjustment",
  ],
};

export const getFlagDescription = (type) => {
  let flagDescription;
  switch (type) {
    case "U":
      flagDescription = "Not Picked";
      break;
    case "P":
      flagDescription = "Picked";
      break;
    case "S":
      flagDescription = "Short Picked";
      break;
    case "E":
      flagDescription = "Scanned";
      break;
    case "C":
      flagDescription = "Cancelled";
      break;
    case "T":
      flagDescription = "STO Created";
      break;
    default:
    // code block
  }
  return flagDescription;
};

export function getJqueryPageURL(path) {
  let url = "";

  switch (path) {
    case "packedOrders":
      url = `/som-static/packedOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "acknowledgeOrders":
      url = `/som-static/acknowledgeOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "openOrders":
      url = `/som-static/openOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "orderManifest":
      url = `/som-static/orderManifest.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "returnSearchOrder":
      url = `/som-static/returnSearchOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "returnAcceptedOrder":
      url = `/som-static/returnAcceptedOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "processedReturnOrder":
      url = `/som-static/processedReturnOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "downloadInventory":
      url = `/som-static/downloadInventory.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "uploadInventory":
      url = `/som-static/uploadInventory.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "searchOrder":
      url = `/som-static/searchOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "summary":
      url = `/som-static/summary.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "forwardUploadGrnOrders":
      url = `/som-static/forwardUploadGrnOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "forwardDownloadHoldOrders":
      url = `/som-static/forwardDownloadHoldOrders.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "storeManagerForwardSerach":
      url = `/som-static/storeManagerForwardSerach.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "storeManagerReturnSerach":
      url = `/som-static/storeManagerReturnSerach.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "nodeCapacityUpload":
      url = `/som-static/nodeCapacityUpload.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "nodeControlUpload":
      url = `/som-static/nodeControlUpload.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "nodeSafetyFactorUpload":
      url = `/som-static/nodeSafetyFactorUpload.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    case "dropstoreSearchOrder":
      url = `/som-static/dropstoreSearchOrder.html?store_node=${getStoreNode()}&user_name=${getUserName()}&origin=${getStoreOrigin()}`;
      break;
    default:
      break;
  }
  return url;
}

export function getPrinterPreference() {
  var printerPreference = localStorage.getItem("printer_preference");

  if (printerPreference) {
    return printerPreference;
  } else {
    return "A4";
  }
}

export function setPrinterPreference(option) {
  localStorage.setItem("printer_preference", option);
}

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  exdays = exdays || 1;
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();
};

export const isAuthenticated = (isKeyCloak) => {
  if (typeof isKeyCloak !== "undefined" && isKeyCloak) {
    if (localStorage.getItem("access_token")) {
      return true;
    }
    return false;
  } else {
    const storeNode = localStorage.getItem("store_node");
    let cookieValue = storeNode + "_JSESSIONID";
    if (getCookie(cookieValue)) {
      return true;
    } else {
      return false;
    }
  }
};

export const isLoggedIn = () => {
  if (localStorage.getItem("access_token")) {
    return true;
  }
  return false;
};

export const toGetShipNode = () => {
  const user = decodeToken();
  if (user && user.shipnode) {
    return user.shipnode;
  }
  return;
};

export const isUserSupervisorHq = () => {
  const user = decodeToken();
  let isSupervisor = false;
  if (user && user.resource_access && user.resource_access["ajiosom-ui"]) {
    let roles = user.resource_access["ajiosom-ui"]["roles"];
    /** if user has supervisor or supervisor_hq access */ 
    isSupervisor = (roles.includes("supervisor_hq") || roles.includes("supervisor")); 
    return isSupervisor;
  }
  return;
};

export const isUserHq = () => {
  const user = decodeToken();
  let isSupervisor = false;
  if (user && user.resource_access && user.resource_access["ajiosom-ui"]) {
    let roles = user.resource_access["ajiosom-ui"]["roles"];
    /** if user has supervisor or supervisor_hq access */ 
    isSupervisor = (roles.includes("supervisor_hq")); 
    return isSupervisor;
  }
  return;
};

export const getRoles = () => {
  const user = decodeToken();
  if (user && user.groupnames) {
    let roles = user["groupnames"];
    return roles;
  }
  return;
};

export const getKeyClockToken = () => {
  if (localStorage.getItem("access_token")) {
    return localStorage.getItem("access_token");
  }
  return;
};

export const decodeToken = () => {
  var token = localStorage.getItem("access_token");
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  return;
};

/**
 * Logout from Server
 */
export function logout() {
  const localStorageKeys = [
    "origin",
    "seller_org_code",
    "user_type",
    "user_name",
    "store_node",
    "access_token",
    "refresh_token",
    "lineFilter",
  ];
  localStorageKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
  deleteAllCookies();
  sessionStorage.clear();
  window.location = "/index.html?origin=MS1hamlv#/login";
}
