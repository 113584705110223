const trackOrderMenu = [
    {
        path: "/trackOrder/readyForPickUp",
        menuName: "Ready for Pick Up",
        importPath: 'PickFromStore/ReadyForPickUpOrder/ReadyForPickUpOrder',
        component: 'ReadyForPickUpOrder',
        showOnMenu: true
    },
    {
        path: "/trackOrder/readyForInward",
        menuName: "Ready for Inward",
        importPath: 'PickFromStore/ReadyForInward/ReadyForInward',
        component: 'ReadyForInward',
        showOnMenu: true
    },
    {
        path: "/trackOrder/onTheWay",
        menuName: "On The Way",
        importPath: 'PickFromStore/TrackOnTheWayOrder/TrackOnTheWayOrder',
        component: 'TrackOnTheWayOrder',
        showOnMenu: true
    },
    {
        path: "/trackOrder/allOrders",
        menuName: "All Order",
        importPath: 'PickFromStore/TrackAllOrder/TrackAllOrder',
        component: 'TrackAllOrder',
        showOnMenu: true
    }
]

export default trackOrderMenu;