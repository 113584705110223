const pickFromStoreMenu = [
    {
        path: "/pickFromStoreHome",
        menuName: "Hidden route",
        importPath: "PickFromStore/PickFromStoreHome",
        component: 'PickFromStoreHome',
        showOnMenu: false
    },
    {
        path: "/ProcessDelivery",
        menuName: "Hidden route",
        importPath: "PickFromStore/ProcessDelivery/ProcessDelivery",
        component: 'ProcessDelivery',
        showOnMenu: false
    },
    {
        path: "/OrderListing",
        menuName: "Hidden route",
        importPath: "ProcessReturn/OrderListing",
        component: 'OrderListing',
        showOnMenu: false
    },
    {
        path: "/IncompleteOrderProcessing",
        menuName: "Hidden route",
        importPath: "PickFromStore/IncompleteOrderProcessing/IncompleteOrderProcessing",
        component: 'IncompleteOrderProcessing',
        showOnMenu: false
    },
    // {
    //     path: "/trackOrder",
    //     menuName: "Hidden route",
    //     importPath: "PickFromStore/AppDrawer/AppDrawer",
    //     component: 'AppDrawer',
    //     showOnMenu: false,
    // },
    {
        path: "/taskLanding",
        menuName: "All Order",
        importPath: 'TaskLanding',
        component: 'TaskLanding',
        showOnMenu: false
    }
]

export default pickFromStoreMenu;