import React, { useState, useEffect, useContext } from "react";
import { CustomerContext } from "../../contexts/CustomerContext";
import { Button } from "@material-ui/core";
import {
  successHandler,
  catchHandler,
  errorHandler,
  authToken,
  setCookie,
  logout,
} from "../../helpers/Utility";
import { withSnackbar } from "notistack";
import http from "../../apis/http";
import { withRouter } from "react-router-dom";

function Unauthorized(props) {
  const { userName, password } = useContext(CustomerContext);

  const loginAgain = () => {
    const { enqueueSnackbar } = props;
    let payload = {
      payload: {
        username: btoa(userName),
        password: btoa(password),
      },
    };
    http
      .post("/dashboard/v1/login_api?origin=MS1hamlv", payload, {
        headers: {
          is_encrypted: true,
        },
      })
      .then((res) => {
        if (res.data.status_flag) {
          localStorage.setItem("seller_org_code", res.data.seller_org_code);
          localStorage.setItem("user_type", res.data.user_type);
          localStorage.setItem("user_name", res.data.user_name);
          localStorage.setItem("store_node", res.data.store_node);
          setCookie(
            res.data.store_node + "_JSESSIONID",
            res.headers["x-auth-token"].replace("]", "").replace("[", "")
          );
          props.history.push("/som/home");
        } else {
          errorHandler(enqueueSnackbar, res.data.status_content);
          // logout();
          // props.history.replace("/login");
        }
      })
      .catch((err) => {
        errorHandler(enqueueSnackbar, err);
      });
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>
        This page is not available right now, fetching token. Please wait for
        sometime or click to <Button onClick={loginAgain}>Refresh</Button>
      </h2>
    </div>
  );
}

export default withSnackbar(withRouter(Unauthorized));
