import {
  STORE_ADMIN,
  FOC_CHILD,
  FOC_MASTER,
  ROD,
  ROD_SUPERVISOR,
  PICK_FROM_STORE,
  DROP_AT_STORE,
  PICKER_PACKER_MANIFEST_TRENDS,
  RETURNS,
  SUPERVISOR,
  PICKER_PACKER_MANIFEST_SUPERVISOR,
  PICKER_PACKER_MANIFEST_NONTRENDS,
  COMMON_NAV
} from "./configs/menu";
import pickFromStoreMenu from "./configs/pickFromStroreMenu";
import readyForInwardMenu from "./configs/ReadyForInwardMenu";
import storeAndDropAtStore from "./configs/storeAndDropAtStoreMenu";
import storeUserMenu from "./configs/storeUserMenu";
import dropAtStoreMenu from "./configs/fullScreenMenu/dropAtStoreMenu";
import dropAtStoreNewMenu from "./configs/dropAtStoreNewMenu";
import menu from "./configs/fullScreenMenu/nodeControlMenu";
import refundOnDemandMenu from "./configs/refundOnDemand";
import storeMenuNewTrends from "./configs/storeMenuNewTrends";
import relianceJewelStoreMenu from "./configs/relianceJewelStoreMenu";
import somSupervisorMenu from "./configs/somSupervisorMenu";
import { focMenu_Master } from "./configs/FocmenuMaster";
import { focMenu_Child } from "./configs/FocmenuChild";
import storeMenuNew from "./configs/storeUserMenuNew";

function createMenu(group) {
  let menu = [];
  switch (group) {
    case "fwd_consolidation_grp":
      menu = menu;
      break;
    case "TRENDS_GROUP_DAS":
      menu = [...storeAndDropAtStore, ...pickFromStoreMenu, ...dropAtStoreMenu];
      break;
    case "TRENDS_GROUP_CANCEL_DAS":
      menu = [...storeAndDropAtStore, ...pickFromStoreMenu, ...dropAtStoreMenu];
      break;
    case "JIO_POINT_GROUP":
      menu = [...dropAtStoreNewMenu, ...pickFromStoreMenu, ...dropAtStoreMenu];
      break;
    case "JIO_POINT_NON_CASH_GRP":
      menu = [...dropAtStoreNewMenu, ...pickFromStoreMenu, ...dropAtStoreMenu];
      break;
    case "FWD_CONSOLIDATION_GRP":
      menu = focMenu_Child;
      break;
    case "FWD_CONSLDT_MAST_GRP":
      menu = focMenu_Master;
      break;
    case "AJIO_STORES_GRP":
      menu = storeMenuNew;
      break;
    case "RELIANCE_JEWEL_GROUP":
      menu = relianceJewelStoreMenu;
      break;
    case "SOM_SUPERVISOR":
      menu = somSupervisorMenu;
      break;
    case "REFUND_ON_DEMAND_SUP":
      menu = refundOnDemandMenu;
      break;
    case "REFUND_ON_DEMAND":
      menu = refundOnDemandMenu;
      break;
    case "TRENDS_STORE_GRP":
      menu = storeMenuNewTrends;
      break;
    case "TRENDS_STORE_GRP_CANCEL":
      menu = storeMenuNewTrends;
      break;
    case "TRENDS_GROUP":
      menu = storeMenuNewTrends;
      break;
    case "TRENDS_GROUP_CANCEL":
      menu = storeMenuNewTrends;
      break;
    case "store_admin":
      menu = STORE_ADMIN;
      break;
    case "foc_child":
      menu = FOC_CHILD;
      break;
    case "foc_master":
      menu = FOC_MASTER;
      break;
    case "rod_user":
      menu = ROD;
      break;
    case "rod_supervisor":
      menu = ROD_SUPERVISOR;
      break;
    case "jiopoint_cash":
      menu = [...PICK_FROM_STORE, ...DROP_AT_STORE];
      break;
    case "jiopoint_non_cash":
      menu = [...PICK_FROM_STORE, ...DROP_AT_STORE];
      break;
    case "trends":
      menu = [...PICKER_PACKER_MANIFEST_TRENDS];
      break;
    case "trends_pfs":
      menu = [...PICKER_PACKER_MANIFEST_TRENDS, ...PICK_FROM_STORE];
      break;
    case "sfs_normal":
      menu = [...PICKER_PACKER_MANIFEST_TRENDS, ...DROP_AT_STORE];
      break;
    case "sfs_supervisor":
      menu = [
        ...PICKER_PACKER_MANIFEST_SUPERVISOR,
        ...DROP_AT_STORE,
        ...SUPERVISOR,
      ];
      break;
    case "sfs_hq":
      menu = [
        ...PICKER_PACKER_MANIFEST_SUPERVISOR,
        ...DROP_AT_STORE,
        ...SUPERVISOR,
      ];
      break;
    case "nontrends":
      menu = [...PICKER_PACKER_MANIFEST_NONTRENDS, ...DROP_AT_STORE];
      break;
    case "nontrends_supervisor":
      menu = [
        ...PICKER_PACKER_MANIFEST_NONTRENDS,
        ...DROP_AT_STORE,
        ...SUPERVISOR,
      ];
      break;
    default:
      menu = [];
      break;
  }
  menu = [...menu, ...COMMON_NAV];
  return menu;
}

export const getMenuForRespectiveRole = (group) => {
  let finalList = [];
  let result = [];
  if (group && group.length > 0) {
    group.map((item) => {
      const menu = createMenu(item);

      finalList = [...finalList, ...menu]; //combining the menu section

      /** To Remove duplicate menu Objects from Menu Array */
      result = finalList.reduce((unique, o) => {
        if (!unique.some((obj) => obj.path === o.path)) {
          unique.push(o);
        }
        return unique;
      }, []);
    });
  }

  return result;
};
