import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { authToken, isAuthenticated, isLoggedIn } from "../Utility";
import Unauthorized from "../../modules/common/Unauthorized";

function ProtectedRoutes ({component: Component, isKeyCloak, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => isLoggedIn()
        ? (isAuthenticated(isKeyCloak) ? <Component {...props} /> : <Unauthorized />)
        : <Redirect to="/login" />}
    />
  )
}

export default withRouter(ProtectedRoutes);